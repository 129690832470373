enum CallType {
  AGENT = 'AGENT',
  AGENT_PREVIEW = 'AGENT_PREVIEW',
  AUTODIAL = 'AUTODIAL',
  INBOUND = 'INBOUND',
  INBOUND_VOICEMAIL = 'INBOUND_VOICEMAIL',
  INTERNAL = 'INTERNAL',
  INTERNAL_VOICEMAIL = 'INTERNAL_VOICEMAIL',
  OUTBOUND = 'OUTBOUND',
  OUTBOUND_PREVIEW = 'OUTBOUND_PREVIEW',
  OUTBOUND_VOICEMAIL = 'OUTBOUND_VOICEMAIL',
  QUEUE_CALLBACK = 'QUEUE_CALLBACK',
  TEST = 'TEST',
  TEST_PREVIEW = 'TEST_PREVIEW',
  VISUAL = 'VISUAL',
}

export default CallType;
