import { LoginState } from '../types';

enum Five9LoginState {
  ACCEPT_NOTICE = 'ACCEPT_NOTICE',
  RELOGIN = 'RELOGIN',
  SELECT_SKILLS = 'SELECT_SKILLS',
  SELECT_STATION = 'SELECT_STATION',
  SPECIFY_CLIENT_TIMEZONE = 'SPECIFY_CLIENT_TIMEZONE',
  WORKING = 'WORKING',
}

const toLoginState = (five9LoginState: Five9LoginState): LoginState => {
  switch (five9LoginState) {
    case Five9LoginState.ACCEPT_NOTICE:
    case Five9LoginState.RELOGIN:
    case Five9LoginState.SELECT_SKILLS:
      return LoginState.StationSelected;
    case Five9LoginState.SELECT_STATION:
    case Five9LoginState.SPECIFY_CLIENT_TIMEZONE:
      return LoginState.UserIdentified;
    case Five9LoginState.WORKING:
    default:
      return LoginState.LoggedIn;
  }
};

export default toLoginState;
