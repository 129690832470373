import { Dispatch } from 'react';

import setStation from './actions/setStation';
import setSkills from './actions/setSkills';

export enum AgentEventsSupplement {
  CALL_IS_MUTED = 'CALL_IS_MUTED',
  CALL_UPDATED = 'CALL_UPDATED',
  LOGOUT = 'LOGOUT',
}

export enum Channels {
  CALL = 'CALL',
  CHAT = 'CHAT',
  EMAIL = 'EMAIL',
  SOCIAL = 'SOCIAL',
  TEXT = 'TEXT',
  VOICE_MAIL = 'VOICE_MAIL',
}

export enum CallState {
  CONFERENCE_PARTICIPANT_CONSULTING = 'CONFERENCE_PARTICIPANT_CONSULTING',
  CONFERENCE_PARTICIPANT_RINGING = 'CONFERENCE_PARTICIPANT_RINGING',
  CONFERENCE_PARTICIPANT_TALKING = 'CONFERENCE_PARTICIPANT_TALKING',
  FINISHED = 'FINISHED',
  OFFERED = 'OFFERED',
  ON_HOLD = 'ON_HOLD',
  PARKED = 'PARKED',
  QUEUED = 'QUEUED',
  RINGING_ON_OTHER_SIDE = 'RINGING_ON_OTHER_SIDE',
  RINGING_ON_OUR_SIDE = 'RINGING_ON_OUR_SIDE',
  TALKING = 'TALKING',
  WRAP_UP = 'WRAP_UP',
}

export enum CampaignState {
  NOT_RUNNING = 'NOT_RUNNING',
  RESETTING = 'RESETTING',
  RUNNING = 'RUNNING',
  STARTING = 'STARTING',
  STOPPING = 'STOPPING',
}

export enum InteractionApiEventName {
  CallAccepted = 'CallAccepted',
  CallEnded = 'CallEnded',
  CallFinished = 'CallFinished',
  CallRejected = 'CallRejected',
  CallStarted = 'CallStarted',
}

export enum LoginState {
  LoggedIn = 'LoggedIn',
  LoggedOut = 'LoggedOut',
  StationSelected = 'StationSelected',
  UserIdentified = 'UserIdentified',
}

export enum PhoneProviderEnum {
  five9 = 'five9',
  twilio = 'twilio',
}

export enum PhoneApiEnv {
  dev = 'dev',
  local = 'local',
  prod = 'prod',
  qa = 'qa',
}

export enum ServiceWorkerMessageType {
  accept = 'accept',
  decline = 'decline',
  disconnected = 'disconnected',
  offer = 'offer',
  offerResponse = 'offerResponse',
}

export enum StationEnum {
  EMPTY = 'EMPTY',
  GATEWAY = 'GATEWAY',
  PSTN = 'PSTN',
  SOFTPHONE = 'SOFTPHONE',
}

export enum TransferDetail {
  'Conference Successful' = 1,
  'Transfer Failed' = 2,
  'Transfer Cancelled - Lead Closed' = 7,
  'Transfer Cancelled - Lead Assigned' = 8,
  'Advisor Abandon During Conference' = 9,
  'Customer Abandon During Conference' = 10,
  'Error - Advisor Connection' = 11,
  'Error' = 12,
}

export enum TransferCancellationReason {
  'Unknown' = 0,
  'Customer Abandon' = 1,
  'No Advisors to Ping' = 2,
  'Warm Transfer' = 3,
  'Auto Assign' = 4,
  'Customer Abandon - Before Hold' = 5,
  'Customer Abandon - During Hold' = 6,
  'No Advisor Accepted' = 7,
  'Advisor No Answer' = 8,
  'Advisor Abandon' = 9,
  'Lead Closed' = 10,
}

export enum UserRole {
  Agent = 'Agent',
  DomainAdmin = 'DomainAdmin',
  DomainSupervisor = 'DomainSupervisor',
}

export enum VapidPublicKey {
  dev = 'BI6VBrC0cthVeKC3AObwPyASDeVt0D8modA4lxrCm7XTwCJ5NwbGaakDaJ96XxV-PPoeTJ4s7bb4rBaTdZ8jnMs',
  local = 'BI6VBrC0cthVeKC3AObwPyASDeVt0D8modA4lxrCm7XTwCJ5NwbGaakDaJ96XxV-PPoeTJ4s7bb4rBaTdZ8jnMs',
  prod = 'BJk6whJ_Q421lxusRgFO7kIB9EqoKpudw92IUKTpMsPOkcPccksKhZ9r44VohuQQOPcSCuy6oyOhoQjARRka3IM',
  qa = 'BEdUx4IWLVBSEVxaqtx4qg3MpMgDHmLua417ciSJI9ElULYjMddtiDBZ1R_2liaiO6rNXhj6Gm6_QH4ptpQ_2ck',
}

export enum WarmTransferListVersion {
  AUTO = 'AUTO',
  LIS = 'LIS',
  TARGETED = 'TARGETED',
}

export enum WarmTransferResult {
  TransferCompleted = 1,
  TransferFailed = 2,
  NoAdvisorsLoggedIn = 3,
  NoAdvisorsAvailableForWT = 4,
  NoAdvisorsAccepted = 5,
  NoAdvisorsInReadyState = 6,
  TransferCancelledLeadClosed = 7,
  TransferCancelledLeadAssigned = 8,
  AdvisorAbandonDuringConference = 9,
  CustomerAbandonDuringConference = 10,
  ErrorAdvisorConnection = 11,
  Error = 12,
}

export enum WarmTransferType {
  WarmTransfer = 1,
  RequestedAdvisor = 2,
  ReInquiry = 3,
}

export interface Advisor {
  agentId: string | null;
  dialPhone: string | null;
  displayName: string | null;
  displayOrder: number | null;
  displayPhoneNumber: string | null;
  email: string | null;
  hasWarmTransferSkill: boolean | null;
  isAutoAssigned: boolean | null;
  isAvailable: boolean | null;
  isBackstop: boolean | null;
  isLeadOfferedRecently: boolean | null;
  isLoggedIn: boolean | null;
  isNational: boolean | null;
  isNationalDisplay: string | null;
  isOnAnotherCall: boolean | null;
  isReadyForCall: boolean | null;
  isRegional: boolean | null;
  isStatusLoaded: boolean | null;
  isWarmTransferBackup: boolean | null;
  phoneTollFree: string | null;
  phoneWork: string | null;
  priorityId: number | null;
  status: number | null;
  statusCalculated: string | null;
  statusForDisplay: string | null;
  telephonyProvider: string | null;
  telephonyUserName: string | null;
  userId: number;
  userName: string | null;
  warmTransferId: string;
  warmTransferProcessId: number | null;
}

export interface AgentState {
  id: string;
  name: string;
}

export interface AgentInfo {
  availableChannels: Channels[];
  email: string;
  id: string;
  name: string;
  userName: string;
}

export interface SelectableAgentState {
  id: string;
  isSelectable: boolean;
  name: string;
}

export interface SelectableLogoutReason {
  id: string;
  name: string;
  selectable: boolean;
}

export interface CallData {
  activeContact?: ConnectContact;
  agent: string;
  agentExtension: string;
  agentName: string;
  ani: string;
  callAttempts: number;
  callId: string;
  callType: string;
  callbackId: string;
  callbackNumber: string;
  campaignId: string;
  campaignName: string;
  click2DialData: string;
  contactDisplayName: string;
  distributionMode: string;
  dnis: string;
  eventName?: InteractionApiEventName;
  eventReason?: string;
  interactionId: string;
  isLastCallAttempt: boolean;
  number: string;
  sessionId: string;
  state?: CallState;
  type: string;
}

export interface CallLogData {
  duration: number;
  handleTime: number;
  holdTime: number;
  startTime: number;
  talkTime: number;
  wrapTime: number;
}

export interface ConferenceData {
  callType: string;
  campaignId: string;
  eventReason: string;
  id: string;
  state: string;
}

export type CallPreview = {
  campaignId: string;
  contact: ConnectContact;
  id: string;
  state: string;
};

export interface ReadyChannels {
  notReadyReasonCodeId: string;
  readyChannels: Channels[];
}

export interface Disposition {
  id: string;
  name: string;
  type: string;
}

export interface Campaign {
  campaignType: string;
  id: string;
  name: string;
  state: CampaignState;
}

export interface ConnectContact {
  SLA: string | null;
  audienceKey: string | null;
  contactId: string | null;
  desiredCity: string | null;
  desiredPostalCode: string | null;
  email: string | null;
  eventReason: string | null;
  familyFileId: number | null;
  firstName: string | null;
  inquiryId: number | null;
  lastName: string | null;
  oneId: string | null;
  phone: string | null;
  relationToResident: string | null;
  residentName: string | null;
  sourceId: number | null;
  subSourceId: number | null;
  submissionUrl: string | null;
  telephonyId: string | null;
  utmCampaign: string | null;
  utmContent: string | null;
  utmMedium: string | null;
  utmSource: string | null;
  utmTerm: string | null;
}

export interface InquiryField {
  dataType: string;
  id: string;
  name: string;
  primary: boolean;
}

export interface Recording {
  createdAt: Date;
  recordingId: string;
  recordingLength: number;
  recordingName: string;
  url?: string;
}

export interface Voicemail {
  activeContact: ConnectContact;
  campaignId: string;
  comment: string;
  contactId: string;
  createdAt: Date;
  firstName: string;
  lastName: string;
  length: number;
  phoneNumber: string;
  recordings: Recording[];
  voicemailId: string;
  voicemailState: string;
  voicemailType: string;
}

export interface PageInfo {
  endCursor: string;
  hasNextPage: boolean;
}

export interface Skill {
  id: string;
  name: string;
}

export interface VoicemailConnection {
  items: Voicemail[];
  pageInfo: PageInfo;
}

export interface PhoneStateType {
  activeCampaigns: Campaign[];
  activeConference: ConferenceData;
  activeContact: ConnectContact;
  agentInfo: AgentInfo;
  agentState: AgentState;
  agentStates: SelectableAgentState[];
  auth: string;
  availableAdvisors: Advisor[];
  availableChannels: Channels[];
  callAttempt: CallAttempt;
  callData: CallData;
  callId: string;
  callLogData: CallLogData;
  callPreview: CallPreview;
  callState: CallState | null;
  campaign: Campaign;
  disposition: Disposition;
  dispositions: Disposition[];
  error: Error | null;
  incomingVoicemail: Voicemail;
  inquiryFields: InquiryField[];
  interactionId: string;
  isAuthenticated: boolean;
  isFetching: boolean;
  isFetchingSkills: boolean;
  isHolding: boolean;
  isLoading: boolean;
  isMuted: boolean;
  isPushEnabled: boolean;
  loginState: LoginState;
  logoutReasonCodes: SelectableLogoutReason[];
  personalVoicemails: VoicemailConnection;
  readyChannels: ReadyChannels;
  skillIds: string[];
  skillVoicemail: Voicemail;
  skills: Skill[];
  voicemail: Voicemail;
  warmTransfer: WarmTransfer | null;
  warmTransferOffer: WarmTransferOffer | null;
}

export interface PushMessage {
  action?: string | null;
  agentId: string;
  email: string;
  type: ServiceWorkerMessageType;
}

export interface ConnectUser {
  agentId: string;
  email: string | null;
  extension: string | null;
  fullName: string | null;
  isLoggedIn: boolean;
  role: UserRole;
  userName: string;
}

export interface WarmTransfer {
  acceptedSlaAgentIds: string[];
  error?: Error | null;
  familyFileId?: number | null;
  isWarmTransferCompleted: boolean;
  isWarmTransferInitiated: boolean;
  leadId?: number | null;
  pingGroup: number | undefined;
  slaOfferTimeout: number | undefined;
  srcAgentId: string;
  warmTransferId: string;
  winningSlaAgentId: string | undefined;
}

export interface WarmTransferOffer extends PushMessage {
  familyFileId?: string | null;
  leadId?: string | null;
  listVersion: WarmTransferListVersion;
  warmTransferId: string;
  warmTransferProcessId: number;
}

export type PhoneActionType =
  | {
      agentInfo: AgentInfo;
      agentState: AgentState;
      agentStates: SelectableAgentState[];
      callPreviews: CallPreview[];
      calls: CallData[];
      campaigns: Campaign[];
      inquiryFields: InquiryField[];
      loginState: LoginState;
      logoutReasonCodes: SelectableLogoutReason[];
      skillIds: string[];
      skills: Skill[];
      type: 'set_agent';
      voicemailConnection: VoicemailConnection;
    }
  | { agentState: AgentState; type: 'set_agent_state' }
  | { agentStates: SelectableAgentState[]; type: 'set_agent_states' }
  | { payload: unknown; type: 'set_agents_ready' }
  | { auth: string; type: 'set_auth' }
  | { availableAdvisors: Advisor[]; type: 'set_available_advisors' }
  | { availableChannels: Channels[]; type: 'set_available_channels' }
  | {
      callAttemptId: number;
      callAttempts: number;
      callId: string;
      isLastCallAttempt: boolean;
      type: 'set_call_attempt';
    }
  | { callData: CallData; type: 'set_call_data' }
  | { callLogData: CallLogData; type: 'set_call_log_data' }
  | { callId: string; type: 'set_call_id' }
  | { payload: any; type: 'set_call_state' }
  | { campaign: Campaign; type: 'set_campaign' }
  | { campaigns: Campaign[]; type: 'set_active_campaigns' }
  | { activeConference: ConferenceData; type: 'set_active_conference' }
  | { context: any; payload: any; type: 'set_active_contact' }
  | { disposition: Disposition; type: 'set_disposition' }
  | { dispositions: Disposition[]; type: 'set_dispositions' }
  | { error: Error | null; type: 'set_error' }
  | { skills: Skill[]; type: 'set_skills' }
  | { inquiryFields: InquiryField[]; type: 'set_inquiry_fields' }
  | { isFetching: boolean; type: 'set_fetching' }
  | { isFetchingSkills: boolean; type: 'set_fetching_skills' }
  | { isHolding: boolean; type: 'set_holding' }
  | { payload: any; type: 'set_incoming_voicemail_payload' }
  | { isLoading: boolean; type: 'set_loading' }
  | { loginState: LoginState; type: 'set_login_state' }
  | { isMuted: boolean; type: 'set_muted' }
  | { isPushEnabled: boolean; type: 'set_push_enabled' }
  | { payload: CallPreview; type: 'set_call_preview' }
  | { payload: any; type: 'set_skill_voicemail_payload' }
  | { readyChannels: ReadyChannels; type: 'set_current_state' }
  | { skillIds: string[]; type: 'set_skill_ids' }
  | { skillVoicemail: Voicemail; type: 'set_skill_voicemail' }
  | { state: PhoneStateType; type: 'set_state' }
  | { type: 'set_voicemail'; voicemail: Voicemail }
  | { type: 'set_voicemails'; voicemailConnection: VoicemailConnection }
  | { type: 'set_warm_transfer'; warmTransfer: WarmTransfer | null }
  | { type: 'set_warm_transfer_offer'; warmTransferOffer: WarmTransferOffer };

export type ExecuteRestApi = (req: {
  method: string;
  path: string;
  payload?: Record<string, unknown>;
}) => Promise<any>;

export type FetchGraphQL = (props: {
  query: string;
  variables: Record<string, any>;
}) => Promise<Record<string, any>>;

export interface ActionProps {
  dispatch: Dispatch<PhoneActionType>;
  fetchGraphQL: FetchGraphQL;
  provider: PhoneProviderEnum;
  state: PhoneStateType;
}

export interface MakeExternalCall {
  (props: { phoneNumber: string; skipDNCCheck: boolean }): Promise<void>;
}

export type Curried<F> = F extends (...args: infer A) => infer R ? R : never;

export interface PhoneContextType extends PhoneStateType {
  acceptSkillVoicemail: () => Promise<{ data: boolean; error: Error | null }>;
  acceptWarmTransfer: (warmTransferProcessId: number) => Promise<void>;
  activateAgentSkill: (
    skillId: string,
    skillName: string,
    level: number
  ) => Promise<{ data: boolean; error: Error | null }>;
  addConferenceParticipant: (agentId: string) => Promise<{ data: boolean; error: Error | null }>;
  addNumberToDnc: (phoneNumber: string) => Promise<{ data: boolean; error: Error | null }>;
  addUserSkill: (
    userName: string,
    skillName: string,
    level: number
  ) => Promise<{ data: boolean; error: Error | null }>;
  callVoicemail: (voicemailId: string) => Promise<{ data: boolean; error: Error | null }>;
  cancelWarmTransfer: () => Promise<void>;
  clearError: () => void;
  completeWarmConference: () => Promise<{ data: boolean; error: Error | null }>;
  completeWarmTransfer: ({
    transferCancellationReasonCode,
    transferDetailCode,
    transferResult,
    transferType,
    warmTransferProcessId,
    warmTransferResult,
  }: {
    transferCancellationReasonCode: number;
    transferDetailCode: number;
    transferResult?: string | null;
    transferType?: string | null;
    warmTransferProcessId: number;
    warmTransferResult: number;
  }) => Promise<{ data: boolean; error: Error | null }>;
  createWarmTransfer: (warmTransferId: string) => Promise<WarmTransfer>;
  deactivateAgentSkill: (skillName: string) => Promise<{ data: boolean; error: Error | null }>;
  declineWarmTransfer: (warmTransferProcessId: number) => Promise<void>;
  deleteCallFromCampaign: (input: {
    campaignName: string;
    inquiryId?: number | null;
    phoneNumber?: string | null;
  }) => Promise<void>;
  deleteCallback: (input: {
    inquiryId?: number | null;
    listName: string;
    phoneNumber?: string | null;
  }) => Promise<void>;
  deleteCallsFromLists: (phoneNumbers: string[]) => Promise<{ data: boolean; error: Error | null }>;
  deleteVoicemail: (voicemailId: string) => Promise<{ data: boolean; error: Error | null }>;
  enablePush: () => Promise<void>;
  endCall: () => Promise<{ data: boolean; error: Error | null }>;
  findNumbersInDnc: (phoneNumbers: string[]) => Promise<string[]>;
  getAgentForTransfer: (input: {
    beaconInquiryId?: number | null;
    city: string;
    contactId: string;
    eligibleForAsla?: boolean | null;
    familyFileId?: number | null;
    leadId?: number | null;
    oneId: string;
    oppgenInquiryId?: number | null;
    poolType?: number | null;
    postalCode: string;
    primaryContactName: string | null;
    primaryContactRelationship: string | null;
    residentName: string | null;
    state: string;
  }) => Promise<Advisor[]>;
  getLoggedInUsers: () => Promise<ConnectUser[]>;
  getMuteState: () => Promise<boolean>;
  getVoicemail: (voicemailId: string) => Promise<Voicemail>;
  getVoicemails: (pageSize?: number, cursor?: string | null) => Promise<void>;
  getWarmTransfer: (warmTransferId: string) => Promise<WarmTransfer>;
  initiateWarmTransfer: (input: {
    beaconInquiryId?: number | null;
    city: string;
    contactId: string;
    eligibleForAsla?: boolean | null;
    familyFileId?: number | null;
    leadId?: number | null;
    oneId: string;
    oppgenInquiryId?: number | null;
    poolType?: number | null;
    postalCode: string;
    primaryContactName: string | null;
    primaryContactRelationship: string | null;
    residentName: string | null;
    slaAgentId?: string | null;
    slaEmailId?: string | null;
    state: string;
  }) => Promise<void>;
  leaveConference: (
    dispositionId: string,
    dispositionName: string
  ) => Promise<{ data: boolean; error: Error | null }>;
  logout: (logoutReasonCodeId: string) => Promise<void>;
  makeExternalCall: (
    campaignId: string,
    phoneNumber: string
  ) => Promise<{ data: boolean; error: Error | null }>;
  makeInternalCall: (destinationAgentId: string) => Promise<{ data: boolean; error: Error | null }>;
  makePreviewCall: () => Promise<{ data: boolean; error: Error | null }>;
  provider: PhoneProviderEnum;
  redial: () => Promise<{ data: boolean; error: Error | null }>;
  removeConferenceParticipant: () => Promise<{ data: boolean; error: Error | null }>;
  removeNumbersFromDnc: (phoneNumbers: string[]) => Promise<{ data: boolean; error: Error | null }>;
  removeUserSkill: (
    userName: string,
    skillName: string
  ) => Promise<{ data: boolean; error: Error | null }>;
  scheduleCallback: (
    inquiryId: number,
    oneId: string,
    callTime: number,
    campaignName: string,
    listName: string,
    phoneNumber: string,
    disposition?: string
  ) => Promise<void>;
  sendDTMF: (sendDTMF: string) => Promise<boolean>;
  setAgentState: (agentStateId: string) => Promise<void>;
  setCampaign: (campaignId: string) => Promise<{ data: boolean; error: Error | null }>;
  setDisposition: (
    callId: string,
    dispositionId: string
  ) => Promise<{
    data: {
      callAttempts: number;
      success: boolean;
      updatedList: {
        listRecordsDeleted: number;
      };
    };
    error: Error | null;
  }>;
  setHolding: (isHolding: boolean) => Promise<void>;
  setMute: (isMuted: boolean) => Promise<boolean>;
  setSkills: Curried<typeof setSkills>;
  setState: (arg: PhoneStateType | (() => void)) => void;
  setStation: Curried<typeof setStation>;
  setVoicemailAsRead: (voicemailId: string) => Promise<{ data: boolean; error: Error | null }>;
  setVoicemailComment: (
    voicemailId: string,
    comment: string
  ) => Promise<{ data: boolean; error: Error | null }>;
  setWarmTransferToSLA: (input: {
    beaconInquiryId?: number | null;
    city: string | null;
    contactId: string;
    eligibleForAsla?: boolean | null;
    familyFileId?: number | null;
    leadId?: number | null;
    oneId: string;
    oppgenInquiryId?: number | null;
    postalCode: string | null;
    primaryContactName: string | null;
    primaryContactRelationship: string | null;
    residentName: string | null;
    slaAgentId?: string | null;
    slaEmailId?: string | null;
    state: string | null;
  }) => Promise<Advisor[]>;
  showNotification: (title: string, options: NotificationOptions) => Promise<void>;
  skipPreviewCall: (dispositionId: string) => Promise<{ data: boolean; error: Error | null }>;
  transferExternalCall: (
    phoneNumber: string,
    dispositionId?: string,
    dispositionName?: string
  ) => Promise<{ data: boolean; error: Error | null }>;
  transferLead: (warmTransferProcessId: number) => Promise<{ data: boolean; error: Error | null }>;
  transferVoicemailToAgent: (
    voicemailId: string,
    agentId: string
  ) => Promise<{ data: boolean; error: Error | null }>;
  transferVoicemailToSkill: (
    voicemailId: string,
    skillId: string
  ) => Promise<{ data: boolean; error: Error | null }>;
}

export interface PhoneProviderProps {
  apiEnv?: PhoneApiEnv;
  email: string;
  iframeContainer: React.FC;
  iframeStyle: Record<string, unknown>;
  provider: PhoneProviderEnum;
  userId: number;
}

export interface Five9Props extends PhoneStateType {
  broadcastChannel: BroadcastChannel;
  dispatch: Dispatch<PhoneActionType>;
  iframeContainer: React.FC;
  iframeStyle: Record<string, unknown>;
  isAuthenticated: boolean;
  isLoading: boolean;
  logLevel?: string | null;
}

export interface CallAttempt {
  callAttemptId: number;
  callAttempts: number;
  callId: string;
  isLastCallAttempt: boolean;
}

export const advisorProps = `
  agentId
  dialPhone
  displayName
  displayOrder
  displayPhoneNumber
  email
  hasWarmTransferSkill
  isAutoAssigned
  phoneTollFree
  phoneWork
  priorityId
  statusForDisplay
  telephonyUserName
  userId
  userName
  warmTransferId
  warmTransferProcessId
`;

export const voicemailProps = `
  comment
  campaignId
  contactId
  createdAt
  firstName
  lastName
  length
  phoneNumber
  recordings {
    createdAt
    recordingId
    recordingLength
    recordingName
    url
  }
  voicemailId
  voicemailState
  voicemailType
`;

export const voicemailConnectionProps = `
items {
  ${voicemailProps}
}
pageInfo {
  endCursor
  hasNextPage
}
`;

export const warmTransferProps = `
  acceptedSlaAgentIds
  familyFileId
  isWarmTransferCompleted
  isWarmTransferInitiated
  lastPingSlaEmail
  lastPingSlaStatus
  leadId
  pingGroup
  srcAgentId
  warmTransferId
  warmTransferResult
  winningSlaAgentId
`;
