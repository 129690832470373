const swFile = 'wt-service-worker.js';
const swUrl = `${window.location.origin}/${swFile}`;

export function arrayBufferToBase64(buffer: ArrayBuffer): string {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
}

function registerSW(): void {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      console.warn('Warm transfer service worker registered.', registration);
    })
    .catch((error) => {
      console.error('Warm transfer service worker error:', error);
    });
}

function attemptRegisterServiceWorker(): void {
  // Check if the service worker can be found.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found.
        console.error(
          `Warm transfer service worker not found.  File ${swFile} should be accessible from ${swUrl}.`
        );
      } else {
        // Service worker found. Proceed as normal.
        registerSW();
      }
    })
    .catch((e) => {
      console.error('Warm transfer service worker error:', e);
    });
}

function registerServiceWorker(): void {
  if ('serviceWorker' in navigator) {
    if (document.readyState === 'complete') {
      attemptRegisterServiceWorker();
    } else {
      window.addEventListener('load', () => {
        attemptRegisterServiceWorker();
      });
    }
  }
}

export default registerServiceWorker;
