enum AgentEvents {
  AGENT_EVENTS = 10001,
  DuplicateConnection = 1020,
  EVENT_ACTIVE_SKILLS_UPDATED = 11,
  EVENT_AGENT_ADDED_TO_SKILL = 39,
  EVENT_AGENT_REMOVED_FROM_SKILL = 19,
  EVENT_AGENTS_READY_PRESENCES_SUBSCRIPTION_UPDATE = 15,
  EVENT_AUDIO_PLAYER_UPDATED = 41,
  EVENT_CALL_CREATED = 30,
  EVENT_CALL_CREATED_DEPRECATED = 3,
  EVENT_CALL_DELETED = 5,
  EVENT_CALL_DELETED_DEPRECATED = 31,
  EVENT_CALL_LOGGING_SETTINGS_CHANGED = 60,
  EVENT_CALL_QUALITY_UPDATED = 52,
  EVENT_CALL_UPDATED = 4,
  EVENT_CALL_VARIABLES_DELETED = 67,
  EVENT_CALL_VARIABLES_UPDATED = 66,
  EVENT_CALLBACK_CREATED = 53,
  EVENT_CALLBACK_DELETED = 55,
  EVENT_CALLBACK_UPDATED = 54,
  EVENT_CALLBACKS_DELETED = 82,
  EVENT_CALLBACKS_UPDATED = 83,
  EVENT_CAMPAIGNS_DELETED = 68,
  EVENT_CAMPAIGNS_UPDATED = 59,
  EVENT_CHANNELS_UPDATED = 56,
  EVENT_CHANNELS_UPDATED_UNDOCUMENTED = 10010,
  EVENT_CLICK_TO_DIAL_REQUEST = 74,
  EVENT_CLICK_TO_DIAL_REQUEST_FAILED = 75,
  EVENT_CONFIGURATION_UPDATED = 7,
  EVENT_CONTACT_UPDATED = 77,
  EVENT_DOMAIN_USERS_CAN_MIGRATE = 84,
  EVENT_GAMIFICATION_AGENT_STAT = 200,
  EVENT_GAMIFICATION_DISPOSITION_STAT = 201,
  EVENT_GAMIFICATION_SKILL_STAT = 202,
  EVENT_GRACEFUL_STATE_TRANSITION_SETTINGS_CHANGES = 79,
  EVENT_INCREMENTAL_STATS_UPDATE = 5012,
  EVENT_INCREMENTAL_USER_PROFILES_UPDATE = 5013,
  EVENT_SKILL_INTERACTIONS_UPDATE = 5014,
  EVENT_PREVIEW_CREATED = 8,
  EVENT_PREVIEW_CREATED_DEPRECATED = 2,
  EVENT_QUEUE_CALLBACK_CREATED = 21,
  EVENT_QUEUE_CALLBACK_CREATED_DEPRECATED = 20,
  EVENT_VOICEMAILS_CREATED = 24,
  EVENT_INTERACTION_SESSION_CREATED = 32,
  EVENT_INTERACTION_SESSION_UPDATED = 6,
  EVENT_LOGGING_CONFIG_CHANGED = 81,
  EVENT_LOGIN_STATE_UPDATED = 17,
  EVENT_LOGOUT_REASON_CODES_DELETED = 51,
  EVENT_LOGOUT_REASON_CODES_UPDATED = 50,
  EVENT_MAINTENANCE_ANNOUNCED = 37,
  EVENT_MAINTENANCE_COMPLETED = 73,
  EVENT_MAINTENANCE_STARTED = 70,
  EVENT_MAKE_CALL_FAILED = 65,
  EVENT_MANUAL_CALL_SETTINGS_CHANGED = 61,
  EVENT_NO_ANSWER_ON_TRANSFERRED_CALL = 40,
  EVENT_NOT_READY_REASON_CODES_DELETED = 49,
  EVENT_NOT_READY_REASON_CODES_UPDATED = 48,
  EVENT_OPTIONS_UPDATED = 13,
  EVENT_PENDING_CONNECTOR_CREATED = 33,
  EVENT_PENDING_CONNECTOR_DELETED = 34,
  EVENT_PERMISSIONS_UPDATED = 14,
  EVENT_PRESENCE_UPDATED = 12,
  EVENT_PREVIEW_DELETED = 9,
  EVENT_PREVIEW_UPDATED = 10,
  EVENT_QUEUE_CALLBACK_DELETED = 23,
  EVENT_QUEUE_CALLBACK_UPDATED = 22,
  EVENT_RESOURCE_INVALIDATED = 57,
  EVENT_SKILL_STATS_SNAPSHOT_SUBSCRIPTION_UPDATE = 29,
  EVENT_SOFTPHONE_DEFAULT_FLAGS_CHANGED = 69,
  EVENT_STATION_NEEDS_RESTART = 42,
  EVENT_STATION_UPDATED = 18,
  EVENT_SUBSCRIPTION_UPDATED = 16,
  EVENT_SWITCHED_TO_BACKUP_HOST = 71,
  EVENT_SWITCHED_TO_PRIMARY_HOST = 72,
  EVENT_TRANSFER_FAILED = 58,
  EVENT_VOICEMAILS_DELETED = 26,
  EVENT_VOICEMAILS_UPDATED = 25,
  EVENT_DASHBOARD_SUBSCRIPTION_CREATED = 62,
  EVENT_DASHBOARD_SUBSCRIPTION_DELETED = 63,
  EVENT_DASHBOARD_SUBSCRIPTION_UPDATED = 64,
  FARM_EVENT = 1004,
  Five9ExceptionDetails = 1,
  InteractionMessages = 10000,
  JMS_SERVER_UNAVAILABLE = 1003,
  Pong = 1202,
  SERVER_MAINTENANCE_SWITCHOVER_RELOGIN = 1001,
  SERVER_MAINTENANCE_SWITCHOVER_RELOGIN_FORCED = 1002,
  SERVER_UNAVAILABLE = 1000,
  ServerConnected = 1010,
  EVENT_ECHO_CALL_DELETED = 36,
}

export default AgentEvents;
