import { ActionProps } from '../types';

const setSkills =
  ({ state, dispatch, fetchGraphQL, provider }: ActionProps) =>
  async ({ skills }: { skills: string[] }): Promise<void> => {
    const { auth } = state;

    const query = `
    mutation SetSkills($input: SetSkillsInput!) {
      setSkills(input: $input) {
        skills
      }
    }
  `;

    const variables = {
      input: {
        auth,
        provider,
        skills,
      },
    };

    const { error } = await fetchGraphQL({ query, variables });
    if (error) return dispatch({ error, type: 'set_error' });
    return dispatch({ isFetching: false, type: 'set_fetching' });
  };

export default setSkills;
