import { v4 as uuid } from 'uuid';
import {
  Advisor,
  AgentState,
  CallData,
  CallLogData,
  CallAttempt,
  Campaign,
  Channels,
  ConnectContact,
  Disposition,
  PhoneStateType,
  ReadyChannels,
  SelectableAgentState,
  Voicemail,
  VoicemailConnection,
  CallPreview,
  ConferenceData,
  LoginState,
  Skill,
  AgentInfo,
  SelectableLogoutReason,
} from './types';

export const getInitialAgentState = function (): AgentState {
  return {
    id: '-1',
    isSelectable: false,
    name: 'Not Ready',
  } as AgentState;
};

const getInitialState = function (): PhoneStateType {
  return {
    activeCampaigns: [] as Campaign[],
    activeConference: {} as ConferenceData,
    activeContact: {} as ConnectContact,
    agentInfo: {} as AgentInfo,
    agentState: getInitialAgentState(),
    agentStates: [] as SelectableAgentState[],
    auth: '',
    availableAdvisors: [] as Advisor[],
    availableChannels: [] as Channels[],
    callAttempt: {} as CallAttempt,
    callData: {} as CallData,
    callId: '',
    callLogData: {} as CallLogData,
    callPreview: {
      contact: {},
    } as CallPreview,
    callState: null,
    campaign: {} as Campaign,
    disposition: {} as Disposition,
    dispositions: [] as Disposition[],
    error: null,
    incomingVoicemail: {} as Voicemail,
    inquiryFields: [],
    interactionId: uuid(),
    isAuthenticated: false,
    isFetching: false,
    isFetchingSkills: false,
    isHolding: false,
    isLoading: true,
    isMuted: false,
    isPushEnabled: false,
    loginState: LoginState.LoggedOut,
    logoutReasonCodes: [] as SelectableLogoutReason[],
    personalVoicemails: {} as VoicemailConnection,
    readyChannels: {} as ReadyChannels,
    skillIds: [] as string[],
    skillVoicemail: {} as Voicemail,
    skills: [] as Skill[],
    voicemail: {} as Voicemail,
    warmTransfer: null,
    warmTransferOffer: null,
  };
};

export default getInitialState;
