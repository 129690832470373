import {
  CallPreview,
  CallState,
  LoginState,
  PhoneActionType,
  PhoneStateType,
  Voicemail,
  VoicemailConnection,
} from './types';
import { v4 as uuid } from 'uuid';
import toContact from './Five9/toContact';
import toVoicemail from './Five9/toVoicemail';
import getInitialState, { getInitialAgentState } from './getInitialState';
import CallType from './Five9/CallType';

function filterSkillVoicemail(voicemailConnection: VoicemailConnection): Voicemail {
  if (voicemailConnection && voicemailConnection.items && voicemailConnection.items.length > 0) {
    const skillVoicemails = voicemailConnection.items.filter(
      (vm: Voicemail) => vm.voicemailType === 'SKILL'
    );
    if (skillVoicemails && skillVoicemails.length > 0) {
      return skillVoicemails[0];
    }
  }
  return {} as Voicemail;
}

function filterPersonalVoicemail(voicemailConnection: VoicemailConnection): VoicemailConnection {
  if (voicemailConnection && voicemailConnection.items && voicemailConnection.items.length > 0) {
    const personalVoicemails = voicemailConnection.items.filter(
      (vm: Voicemail) => vm.voicemailType === 'PERSONAL'
    );
    if (personalVoicemails && personalVoicemails.length > 0) {
      return {
        ...voicemailConnection,
        items: personalVoicemails,
      };
    }
  }
  return {} as VoicemailConnection;
}

const reducer = (state: PhoneStateType, action: PhoneActionType): PhoneStateType => {
  switch (action.type) {
    case 'set_agent': {
      const {
        agentInfo,
        agentState,
        agentStates,
        callPreviews,
        calls,
        campaigns,
        inquiryFields,
        loginState,
        skillIds,
        logoutReasonCodes,
        skills,
        voicemailConnection,
      } = action;
      const callPreview =
        callPreviews && callPreviews[0] ? callPreviews[0] : ({ contact: {} } as CallPreview);
      const newState = {
        ...state,
        activeCampaigns: campaigns,
        agentInfo,
        agentState,
        agentStates,
        availableChannels: agentInfo.availableChannels,
        callPreview,
        inquiryFields,
        isFetching: false,
        isFetchingSkills: false,
        loginState,
        logoutReasonCodes,
        personalVoicemails: filterPersonalVoicemail(voicemailConnection),
        skillIds,
        skillVoicemail: filterSkillVoicemail(voicemailConnection),
        skills,
      };
      for (const call of calls) {
        if (call.callType === CallType.INTERNAL) {
          newState.activeConference = {
            callType: call.callType,
            campaignId: call.campaignId,
            eventReason: call.eventReason || '',
            id: call.interactionId,
            state: call.state || '',
          };
          newState.callId = call.interactionId;
        } else {
          if (!state.callData.interactionId) {
            newState.callData = {
              ...call,
              activeContact: undefined,
            };
          }
          newState.activeContact = call.activeContact || getInitialState().activeContact;
          newState.callState = call.state || getInitialState().callState;
        }
      }
      return newState;
    }
    case 'set_agent_state': {
      const { agentState } = action;
      return {
        ...state,
        agentState,
        isFetching: false,
      };
    }
    case 'set_agent_states': {
      const { agentStates } = action;
      return {
        ...state,
        agentStates,
        isFetching: false,
      };
    }
    case 'set_agents_ready': {
      if (state.loginState === LoginState.UserIdentified) {
        return {
          ...state,
          isFetching: false,
          loginState: LoginState.StationSelected,
        };
      }
      // Note: EVENT_AGENTS_READY_PRESENCES_SUBSCRIPTION_UPDATE triggers this event every 5 seconds.
      return state;
    }
    case 'set_auth': {
      const { auth } = action;
      if (auth === state.auth) return state;
      const initialState = getInitialState();
      return {
        ...initialState,
        auth,
        isFetching: false,
        isLoading: false,
        loginState: state.loginState,
      };
    }
    case 'set_active_campaigns': {
      const { campaigns: activeCampaigns } = action;
      return {
        ...state,
        activeCampaigns,
        isFetching: false,
      };
    }
    case 'set_active_contact': {
      const { payload, context } = action;
      localStorage.activeContact = JSON.stringify(action);
      const activeContact = toContact(payload.activeContact, state.inquiryFields);
      activeContact.eventReason = context.eventReason;
      return {
        ...state,
        activeContact,
        isFetching: false,
      };
    }
    case 'set_call_attempt': {
      const { callAttemptId, callAttempts, callId, isLastCallAttempt } = action;
      const callAttempt = {
        callAttemptId,
        callAttempts,
        callId,
        isLastCallAttempt,
      };
      localStorage.callAttempt = JSON.stringify(callAttempt);
      return {
        ...state,
        callAttempt,
        isFetching: false,
      };
    }
    case 'set_call_data': {
      const { callData } = action;
      localStorage.callData = JSON.stringify(callData);
      return {
        ...state,
        callData,
        isFetching: false,
      };
    }
    case 'set_call_id': {
      const { callId } = action;
      return {
        ...state,
        callId,
        isFetching: false,
      };
    }
    case 'set_call_log_data': {
      const { callLogData } = action;
      return {
        ...state,
        callLogData,
        isFetching: false,
      };
    }
    case 'set_call_preview': {
      const { payload } = action;
      return {
        ...state,
        callPreview: {
          campaignId: payload.campaignId,
          contact: toContact(payload.contact, state.inquiryFields),
          id: payload.id,
          state: payload.state,
        },
        isFetching: false,
      };
    }
    case 'set_call_state': {
      const { payload } = action;
      localStorage.callState = JSON.stringify(payload);
      const callState = payload.state;
      const newState: PhoneStateType = {
        ...state,
        callState,
        isFetching: false,
      };

      // callState event happens asyncronously ahead of callData event, so we clear callData here
      // this prevents the current callState from being in context at the same time as the previous callData
      if (state.callData.interactionId && state.callData.interactionId !== payload.id) {
        console.log(payload.id);
        newState.callData = getInitialState().callData;
      }

      // After each call is finished, generate a new interactionId
      if (state.callState !== newState.callState && callState === CallState.FINISHED) {
        newState.interactionId = uuid();
        newState.isMuted = false;
        newState.warmTransfer = null;
      }

      return newState;
    }
    case 'set_active_conference': {
      const { activeConference } = action;
      return {
        ...state,
        activeConference,
        isFetching: false,
      };
    }
    case 'set_current_state': {
      const { readyChannels } = action;
      const notReadyReasonCodeId = readyChannels.notReadyReasonCodeId;
      const agentState = state.agentStates.filter(
        (agentState) => agentState.id === notReadyReasonCodeId
      )[0];
      return {
        ...state,
        agentState: agentState || getInitialAgentState(),
        readyChannels,
      };
    }
    case 'set_dispositions': {
      const { dispositions } = action;
      return {
        ...state,
        dispositions,
        isFetching: false,
      };
    }
    case 'set_error': {
      const { error } = action;
      return {
        ...state,
        error,
        isFetching: false,
      };
    }
    case 'set_fetching': {
      const { isFetching } = action;
      //if (state.isFetching === isFetching) return state;
      return {
        ...state,
        isFetching,
      };
    }
    case 'set_fetching_skills': {
      const { isFetchingSkills } = action;
      return {
        ...state,
        isFetchingSkills,
      };
    }
    case 'set_holding': {
      const { isHolding } = action;
      return {
        ...state,
        isFetching: false,
        isHolding,
      };
    }
    case 'set_incoming_voicemail_payload': {
      const { payload } = action;
      const incomingVoicemail = toVoicemail(payload, state.inquiryFields, state.auth);
      return {
        ...state,
        incomingVoicemail,
        isFetching: false,
      };
    }
    case 'set_loading': {
      const { isLoading } = action;
      return {
        ...state,
        isLoading,
      };
    }
    case 'set_login_state': {
      const { loginState } = action;
      if (loginState === state.loginState) return state;
      let isAuthenticated = state.isAuthenticated;
      if (loginState === LoginState.LoggedIn) {
        isAuthenticated = true;
      }
      return {
        ...state,
        isAuthenticated,
        isFetching: false,
        loginState,
      };
    }
    case 'set_muted': {
      const { isMuted } = action;
      return {
        ...state,
        isFetching: false,
        isMuted,
      };
    }
    case 'set_push_enabled': {
      const { isPushEnabled } = action;
      return {
        ...state,
        isFetching: false,
        isPushEnabled,
      };
    }
    case 'set_skill_ids': {
      const { skillIds } = action;
      return {
        ...state,
        isFetching: false,
        skillIds,
      };
    }
    case 'set_skills': {
      const { skills } = action;
      return {
        ...state,
        isFetching: false,
        isFetchingSkills: false,
        skills,
      };
    }
    case 'set_state': {
      const { state: nextState } = action;
      return nextState;
    }
    case 'set_voicemails': {
      const { voicemailConnection } = action;
      const newState: PhoneStateType = {
        ...state,
        isFetching: false,
        personalVoicemails: filterPersonalVoicemail(voicemailConnection),
        skillVoicemail: filterSkillVoicemail(voicemailConnection),
      };
      return newState;
    }
    case 'set_skill_voicemail': {
      const { skillVoicemail } = action;
      return {
        ...state,
        isFetching: false,
        skillVoicemail,
      };
    }
    case 'set_skill_voicemail_payload': {
      const { payload } = action;
      const skillVoicemail = toVoicemail(payload, state.inquiryFields, state.auth);
      return {
        ...state,
        isFetching: false,
        skillVoicemail,
      };
    }
    case 'set_voicemail': {
      const { voicemail } = action;
      return {
        ...state,
        isFetching: false,
        voicemail,
      };
    }
    case 'set_warm_transfer': {
      const { warmTransfer } = action;
      return {
        ...state,
        isFetching: false,
        warmTransfer,
      };
    }
    case 'set_available_advisors': {
      const { availableAdvisors } = action;
      return {
        ...state,
        availableAdvisors,
        isFetching: false,
      };
    }
    case 'set_available_channels': {
      const { availableChannels } = action;
      return {
        ...state,
        availableChannels,
        isFetching: false,
      };
    }
    case 'set_warm_transfer_offer': {
      const { warmTransferOffer } = action;
      return {
        ...state,
        isFetching: false,
        warmTransferOffer,
      };
    }
    case 'set_inquiry_fields': {
      const { inquiryFields } = action;
      return {
        ...state,
        inquiryFields,
        isFetching: false,
      };
    }
    default: {
      const { type } = action;
      throw new Error(`${type} is not defined`);
    }
  }
};

export default reducer;
