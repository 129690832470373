import React, { useCallback, useEffect, useRef, useState } from 'react';

import AgentEvents from './Five9/AgentEvents';
import CallType from './Five9/CallType';
import toLoginState from './Five9/toLoginState';
import {
  AgentEventsSupplement,
  CallData,
  ExecuteRestApi,
  Five9Props,
  InteractionApiEventName,
  LoginState,
} from './types';

const script = document.createElement('script');
script.src = 'https://app.five9.com/dev/sdk/crm/latest/five9.crm.sdk.js';
script.type = 'text/javascript';

const Five9: React.FC<Five9Props> = ({
  auth,
  broadcastChannel,
  dispatch,
  iframeContainer: IframeContainer,
  iframeStyle,
  isLoading,
  logLevel,
}) => {
  const [interactionApiState, setInteractionApiState] = useState({
    executeRestApi: (_: { method: string; path: string; payload?: string }) =>
      Promise.resolve({ response: '' }),
    isLoaded: false,
    isMasterPage: (): Promise<boolean> => Promise.resolve(false),
    subscribe: ({}: any): any => console.log,
    subscribeWsEvent: ({}: any): any => console.log,
  });

  const [masterPageState, setMasterPageState] = useState(false);

  const tryInjectScript = useCallback(async () => {
    if (script.parentNode !== document.head) {
      document.head.appendChild(script);
      script.addEventListener('load', () => {
        const { Five9 } = window as any;

        const interactionApi = Five9.CrmSdk.interactionApi();
        const handleInteractionApiEvent = (payload: any, eventName: InteractionApiEventName) => {
          const callData: CallData = {
            ...payload.callData,
            eventName,
          };
          dispatch({ callData, type: 'set_call_data' });
          broadcastChannel.postMessage(
            JSON.stringify({
              context: {
                eventId: AgentEventsSupplement.CALL_UPDATED,
              },
              payLoad: callData,
            })
          );
        };
        interactionApi.subscribe({
          callAccepted: (payload: { callData: CallData }) => {
            handleInteractionApiEvent(payload, InteractionApiEventName.CallAccepted);
          },
          callEnded: (payload: { callData: CallData }) => {
            handleInteractionApiEvent(payload, InteractionApiEventName.CallEnded);
          },
          callFinished: (payload: { callData: CallData }) => {
            handleInteractionApiEvent(payload, InteractionApiEventName.CallFinished);
          },
          callRejected: (payload: { callData: CallData }) => {
            handleInteractionApiEvent(payload, InteractionApiEventName.CallRejected);
          },
          callStarted: (payload: { callData: CallData }) => {
            handleInteractionApiEvent(payload, InteractionApiEventName.CallStarted);
          },
        });
        setInteractionApiState(interactionApi);

        dispatch({ isLoading: false, type: 'set_loading' });
      });
    } else if (isLoading) {
      dispatch({ isLoading: false, type: 'set_loading' });
    }
  }, [broadcastChannel, dispatch, isLoading]);

  const executeRestApi: ExecuteRestApi = useCallback(
    async (req) => {
      try {
        dispatch({ isFetching: true, type: 'set_fetching' });
        const { payload, ...rest } = req;
        const res = await interactionApiState.executeRestApi({
          ...rest,
          payload: JSON.stringify(payload),
        });
        const { response } = res;
        if (response) {
          const parsed = JSON.parse(response);
          if (parsed.five9ExceptionDetail) throw parsed.five9ExceptionDetail;
          dispatch({ isFetching: false, type: 'set_fetching' });
          return parsed;
        }
        dispatch({ isFetching: false, type: 'set_fetching' });
        return res;
      } catch (e) {
        dispatch({ error: e.message, type: 'set_error' });
      }
    },
    [dispatch, interactionApiState]
  );

  const handleWebsocketMessage = useCallback(
    (data) => {
      const message = JSON.parse(data);
      const context = message.context;
      const payload = message.payLoad;

      // APFM events
      switch (context.eventId) {
        case AgentEventsSupplement.LOGOUT:
          localStorage.clear();
          window.location.reload();
          return;
        case AgentEventsSupplement.CALL_UPDATED:
          dispatch({ callData: payload, type: 'set_call_data' });
          return;
        case AgentEventsSupplement.CALL_IS_MUTED:
          dispatch({ isMuted: payload.isMuted, type: 'set_muted' });
          return;
      }

      // Five9 events
      switch (Number(context.eventId)) {
        case AgentEvents.ServerConnected:
        case AgentEvents.EVENT_SKILL_STATS_SNAPSHOT_SUBSCRIPTION_UPDATE:
        case AgentEvents.EVENT_CALL_CREATED_DEPRECATED:
        case AgentEvents.EVENT_CALL_DELETED_DEPRECATED:
        case AgentEvents.EVENT_PREVIEW_CREATED_DEPRECATED:
        case AgentEvents.EVENT_QUEUE_CALLBACK_CREATED_DEPRECATED:
        case AgentEvents.EVENT_STATION_UPDATED:
        case AgentEvents.Pong:
          break;

        case AgentEvents.EVENT_PREVIEW_CREATED:
        case AgentEvents.EVENT_PREVIEW_UPDATED:
        case AgentEvents.EVENT_PREVIEW_DELETED:
          dispatch({
            payload,
            type: 'set_call_preview',
          });
          break;

        case AgentEvents.EVENT_CALL_CREATED:
        case AgentEvents.EVENT_CALL_UPDATED:
        case AgentEvents.EVENT_CALL_DELETED:
          switch (payload.callType) {
            case CallType.INTERNAL:
              dispatch({
                activeConference: {
                  callType: payload.callType,
                  campaignId: payload.campaignId,
                  eventReason: context.eventReason,
                  id: payload.id,
                  state: payload.state,
                },
                type: 'set_active_conference',
              });
              dispatch({ callId: payload.id, type: 'set_call_id' });
              break;
            default:
              dispatch({
                context,
                payload,
                type: 'set_active_contact',
              });
              dispatch({ payload, type: 'set_call_state' });
          }
          break;

        case AgentEvents.EVENT_CHANNELS_UPDATED:
        case AgentEvents.EVENT_CHANNELS_UPDATED_UNDOCUMENTED:
          dispatch({
            availableChannels: payload.currentState.availableChannels,
            type: 'set_available_channels',
          });
          break;

        case AgentEvents.EVENT_ACTIVE_SKILLS_UPDATED:
          dispatch({ skillIds: payload.skillIds, type: 'set_skill_ids' });
          break;

        case AgentEvents.EVENT_PRESENCE_UPDATED:
          if (context.eventReason === 'INITIAL') {
            dispatch({ loginState: LoginState.StationSelected, type: 'set_login_state' });
          }
          dispatch({ readyChannels: payload.currentState, type: 'set_current_state' });
          break;

        case AgentEvents.EVENT_VOICEMAILS_CREATED:
          if (payload.type === 'PERSONAL') {
            dispatch({ payload, type: 'set_incoming_voicemail_payload' });
          }
          if (payload.type === 'SKILL') {
            dispatch({ payload, type: 'set_skill_voicemail_payload' });
          }
          break;

        case AgentEvents.EVENT_VOICEMAILS_UPDATED:
        case AgentEvents.EVENT_VOICEMAILS_DELETED:
          if (payload.type === 'SKILL') {
            dispatch({ payload, type: 'set_skill_voicemail_payload' });
          }
          break;

        case AgentEvents.EVENT_AGENTS_READY_PRESENCES_SUBSCRIPTION_UPDATE:
          dispatch({ payload, type: 'set_agents_ready' });
          break;

        case AgentEvents.EVENT_LOGIN_STATE_UPDATED:
          dispatch({ loginState: toLoginState(payload), type: 'set_login_state' });
          break;

        case AgentEvents.EVENT_AGENT_ADDED_TO_SKILL:
        case AgentEvents.EVENT_AGENT_REMOVED_FROM_SKILL:
          dispatch({ isFetchingSkills: true, type: 'set_fetching_skills' });
          break;

        default:
      }
    },
    [dispatch]
  );

  const hasWebsocket = useRef(false);
  const connectWebsocket = useCallback(
    async (url: string): Promise<void> => {
      if (hasWebsocket.current) return;
      hasWebsocket.current = true;
      const ws = new WebSocket(url);
      let pingIntervalRef: NodeJS.Timeout | null = null;
      ws.onopen = async (): Promise<void> => {
        pingIntervalRef = setInterval(() => {
          ws.send('ping');
        }, 15000);
      };
      ws.onmessage = (e): void => {
        broadcastChannel.postMessage(e.data);
        handleWebsocketMessage(e.data);
      };
      ws.onclose = async (): Promise<void> => {
        hasWebsocket.current = false;
        if (pingIntervalRef) {
          clearInterval(pingIntervalRef);
        }
      };
      ws.onerror = async (_e: any): Promise<void> => {
        hasWebsocket.current = false;
        ws.close();
      };
    },
    [broadcastChannel, handleWebsocketMessage]
  );

  const getMetaData = useCallback(async () => {
    const res = await executeRestApi({
      method: 'GET',
      path: '/appsvcs/rs/svc/auth/metadata',
    });
    if (!res) {
      return;
    }
    const { metadata = {}, context = {}, orgId, tokenId, userId } = res;
    const { farmId } = context;
    const { dataCenters } = metadata;
    if (!farmId || !dataCenters) {
      return;
    }
    const { host, port } = metadata.dataCenters[0].apiUrls[0];

    return dispatch({
      auth: JSON.stringify({
        Authorization: `Bearer-${tokenId}`,
        agentId: userId,
        farmId,
        host,
        orgId,
        port,
      }),
      type: 'set_auth',
    });
  }, [dispatch, executeRestApi]);

  const getLoginState = useCallback(async () => {
    if (auth === '') return;
    const { agentId } = JSON.parse(auth);
    const res = await executeRestApi({
      method: 'GET',
      path: `/appsvcs/rs/svc/agents/${agentId}/login_state`,
    });
    if (!res) return;
    return dispatch({ loginState: toLoginState(res), type: 'set_login_state' });
  }, [auth, dispatch, executeRestApi]);

  broadcastChannel.onmessage = (e: MessageEvent): void => {
    if (!auth) {
      getMetaData();
    }
    handleWebsocketMessage(e.data);
  };

  useEffect(() => {
    getMetaData();
  }, [getMetaData]);

  useEffect(() => {
    // Only connect the websocket if this is the master page.
    if (auth && masterPageState && !hasWebsocket.current) {
      const { host, port } = JSON.parse(auth);
      connectWebsocket(`wss://${host}:${port}/appsvcs/ws/ws-beacon-connect`);
    }
  }, [auth, connectWebsocket, masterPageState]);

  useEffect(() => {
    getLoginState();
  }, [getLoginState]);

  useEffect(() => {
    tryInjectScript();
  }, [dispatch, tryInjectScript]);

  const checkConnectionRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    checkConnectionRef.current && clearInterval(checkConnectionRef.current);
    checkConnectionRef.current = setInterval(async () => {
      try {
        const isMasterPage = await interactionApiState.isMasterPage();

        // This page has become the master page.
        if (!masterPageState && isMasterPage) {
          setMasterPageState(true);
          getMetaData();
        }

        // This page was the authenticated master page when the websocket was closed.
        if (auth && masterPageState && !isMasterPage) {
          broadcastChannel.postMessage(
            JSON.stringify({
              context: {
                eventId: AgentEventsSupplement.LOGOUT,
              },
              payLoad: {},
            })
          );
          localStorage.clear();
          window.location.reload();
        }
      } catch (e) {
        console.log(e);
      }
    }, 1000);
  }, [auth, broadcastChannel, dispatch, getMetaData, interactionApiState, masterPageState]);

  const logLevelParameter = logLevel ? `&f9loglevel=${logLevel}` : '';

  const iframeProps = {
    id: 'owl-connect-client-iframe',
    key: 'owl-connect-client-iframe',
    src: `https://app.five9.com/clients/integrations/adt.main.html?f9verticalthreshold=300px${logLevelParameter}`,
    style: iframeStyle,
    title: 'five9',
  };

  if (isLoading) return null;

  return (
    <IframeContainer>
      <iframe {...iframeProps} />
    </IframeContainer>
  );
};

export default Five9;
