import { ConnectContact, InquiryField } from '../types';

// Retrieve field value by field name from a Five9 event message
const getField = (
  five9Contact: any,
  inquiryFields: InquiryField[],
  fieldName: string
): string | null => {
  if (!five9Contact) return null;
  const inquiryField = inquiryFields.find((cf) => cf.name === fieldName);
  if (!inquiryField) return null;
  return five9Contact.fields[inquiryField.id] || null;
};

// Retrieve activeContact from Five9 event message
const toContact = (five9Contact: any, inquiryFields: InquiryField[]): ConnectContact => {
  const contact: ConnectContact = {
    SLA: getField(five9Contact, inquiryFields, 'SLA') || '',
    audienceKey: getField(five9Contact, inquiryFields, 'audienceKey') || '',
    contactId: getField(five9Contact, inquiryFields, 'UniqueID') || '',
    desiredCity: getField(five9Contact, inquiryFields, 'city') || '',
    desiredPostalCode: getField(five9Contact, inquiryFields, 'zip') || '',
    email: getField(five9Contact, inquiryFields, 'Email') || '',
    eventReason: null,
    familyFileId: Number(getField(five9Contact, inquiryFields, 'Lead ID') || ''),
    firstName: getField(five9Contact, inquiryFields, 'first_name') || '',
    inquiryId: Number(getField(five9Contact, inquiryFields, 'InquiryID') || ''),
    lastName: getField(five9Contact, inquiryFields, 'last_name') || '',
    oneId: getField(five9Contact, inquiryFields, 'oneId') || '',
    phone: getField(five9Contact, inquiryFields, 'number1') || '',
    relationToResident: getField(five9Contact, inquiryFields, 'Relation to Resident') || '',
    residentName: getField(five9Contact, inquiryFields, 'Resident name') || '',
    sourceId: Number(getField(five9Contact, inquiryFields, 'Source_id') || ''),
    subSourceId: Number(getField(five9Contact, inquiryFields, 'Subsource_id') || ''),
    submissionUrl: getField(five9Contact, inquiryFields, 'submissionUrl') || '',
    telephonyId: five9Contact ? five9Contact.id : null,
    utmCampaign: getField(five9Contact, inquiryFields, 'utmCampaign') || '',
    utmContent: getField(five9Contact, inquiryFields, 'utmContent') || '',
    utmMedium: getField(five9Contact, inquiryFields, 'utmMedium') || '',
    utmSource: getField(five9Contact, inquiryFields, 'utmSource') || '',
    utmTerm: getField(five9Contact, inquiryFields, 'utmTerm') || '',
  };

  return contact;
};

export default toContact;
