import { ActionProps, StationEnum } from '../types';

const setStation =
  ({ state, dispatch, fetchGraphQL, provider }: ActionProps) =>
  async ({
    stationId,
    stationType,
  }: {
    stationId: string;
    stationType: StationEnum;
  }): Promise<void> => {
    const { auth } = state;

    const query = `
    mutation SetStation($input: StationInput!) {
      setStation(input: $input)
    }
  `;

    const variables = {
      input: {
        auth,
        provider,
        stationId,
        stationType,
      },
    };

    const { error } = await fetchGraphQL({ query, variables });
    if (error) return dispatch({ error, type: 'set_error' });
    return dispatch({ isFetching: false, type: 'set_fetching' });
  };

export default setStation;
