import { InquiryField, Recording, Voicemail } from '../types';
import toContact from '../Five9/toContact';

const toVoicemail = (payload: any, inquiryFields: InquiryField[], auth: string): Voicemail => {
  const activeContact = toContact(payload.activeContact, inquiryFields);
  const authInfo = JSON.parse(auth);
  return {
    activeContact,
    campaignId: payload.campaignId,
    comment: payload.comment,
    contactId: payload.activeContact ? payload.activeContact.id : null,
    createdAt: payload.created,
    firstName: payload.contact ? payload.contact.firstName : null,
    lastName: payload.contact ? payload.contact.lastName : null,
    length: payload.length,
    phoneNumber: payload.number,
    recordings:
      payload.recordings &&
      payload.recordings.map(
        (rec: any): Recording => ({
          createdAt: rec.created,
          recordingId: rec.id,
          recordingLength: rec.length,
          recordingName: rec.name,
          url:
            rec.url ||
            (payload.type === 'SKILL' && payload.state === 'NEW'
              ? undefined
              : `https://${authInfo.host}/strsvcs/rs/svc/agents/${authInfo.agentId}/interactions/voicemails/${payload.id}/recordings/${rec.id}`),
        })
      ),
    voicemailId: payload.id,
    voicemailState: payload.state,
    voicemailType: payload.type,
  };
};

export default toVoicemail;
