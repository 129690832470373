import React, { useCallback, useMemo, useReducer, useEffect, useRef } from 'react';

import FetchRetry from 'fetch-retry';
import { arrayBufferToBase64 } from 'registerServiceWorker';

import registerServiceWorker from '../registerServiceWorker';
import apiEndpointMap from './apiEndpointMap';
import Five9 from './Five9';
import toLoginState from './Five9/toLoginState';
import getInitialState from './getInitialState';
import PhoneContext from './PhoneContext';
import reducer from './reducer';
import {
  Advisor,
  advisorProps,
  AgentEventsSupplement,
  CallState,
  CampaignState,
  Channels,
  PhoneApiEnv,
  PhoneContextType,
  PhoneProviderEnum,
  PhoneProviderProps,
  PushMessage,
  ServiceWorkerMessageType,
  VapidPublicKey,
  voicemailConnectionProps,
  voicemailProps,
  WarmTransfer,
  WarmTransferOffer,
  warmTransferProps,
} from './types';
import setStation from './actions/setStation';
import setSkills from './actions/setSkills';

registerServiceWorker();

const broadcastChannel = window.BroadcastChannel
  ? new window.BroadcastChannel('ws_beacon_connect')
  : ({
      postMessage: (): void => {
        // Mock to help main-ui tests pass
      },
    } as unknown as BroadcastChannel);

const fetchRetry = FetchRetry(fetch, {
  retryDelay: (attempt: number) => {
    return Math.pow(2, attempt) * 1000;
  },
  retryOn: (attempt: number, error: Error, response: Response): boolean => {
    // 3 retries max
    if (attempt > 3) return false;

    // retry on server errors
    if (error !== null || response.status >= 500) {
      return true;
    }

    return false;
  },
});

const PhoneProvider: React.FC<PhoneProviderProps> = ({
  apiEnv,
  children,
  userId,
  email,
  provider = PhoneProviderEnum['five9'],
  iframeContainer,
  iframeStyle,
}) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());

  const { activeConference, auth, callData, callId, skillVoicemail } = state;

  const iframeWindowInitEvent = useRef<any>();
  const interactionId = useRef(state.interactionId);
  const lastRedialNumber = useRef('');

  const fetchGraphQL = useCallback(
    async (body) => {
      dispatch({ isFetching: true, type: 'set_fetching' });
      try {
        const endpoint = apiEndpointMap[apiEnv || PhoneApiEnv.prod];
        const res = await fetchRetry(endpoint, {
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'apfm-user-email': email,
            interactionid: interactionId.current,
          },
          method: 'POST',
        });
        if (!res.ok) throw Error(res.statusText);
        const json = await res.json();
        const { errors, data = {} } = json;
        if (errors && errors.length && process.env.NODE_ENV !== 'production') {
          console.error('fetchGraphQL error: ', errors);
        }
        const error = errors && errors.length && errors[0];
        if (error) throw error;
        dispatch({ isFetching: false, type: 'set_fetching' });
        return {
          ...data,
          error: null,
        };
      } catch (e) {
        dispatch({ error: e, type: 'set_error' });
        return { error: e };
      }
    },
    [apiEnv, email, interactionId]
  );

  const sendIframeMessage = useCallback((message: any) => {
    if (
      iframeWindowInitEvent.current &&
      iframeWindowInitEvent.current.source &&
      iframeWindowInitEvent.current.source.postMessage
    ) {
      iframeWindowInitEvent.current.source.postMessage(
        {
          ...message,
          sender: 'owl-connect-client',
        },
        iframeWindowInitEvent.current.origin
      );
      return true;
    }
    return false;
  }, []);

  const acceptSkillVoicemail: PhoneContextType['acceptSkillVoicemail'] = useCallback(async () => {
    if (!skillVoicemail || !skillVoicemail.voicemailId) {
      const error = Error('No call in progress.');
      dispatch({ error, type: 'set_error' });
      return { data: false, error };
    }
    const query = `
        mutation AcceptSkillVoicemail($input: AcceptSkillVoicemailInput!) {
          acceptSkillVoicemail(input: $input)
        }
      `;
    const variables = {
      input: {
        auth,
        provider,
        voicemailId: skillVoicemail.voicemailId,
      },
    };
    const { acceptSkillVoicemail: data, error } = await fetchGraphQL({ query, variables });
    return { data: data || false, error };
  }, [auth, fetchGraphQL, provider, skillVoicemail]);

  const acceptWarmTransfer: PhoneContextType['acceptWarmTransfer'] = useCallback(
    async (warmTransferProcessId) => {
      const query = `
        mutation AcceptWarmTransfer($input: AcceptWarmTransferInput!) {
          acceptWarmTransfer(input: $input)
        }
      `;
      const variables = {
        input: {
          auth,
          provider,
          warmTransferProcessId,
        },
      };
      const { acceptWarmTransfer } = await fetchGraphQL({ query, variables });
      if (acceptWarmTransfer !== true) {
        console.error('Problem accepting warm transfer offer');
      }
    },
    [auth, fetchGraphQL, provider]
  );

  const activateAgentSkill: PhoneContextType['activateAgentSkill'] = useCallback(
    async (skillId, skillName, level = 1) => {
      const query = `
        mutation ActivateAgentSkill($input: ActivateAgentSkillInput!) {
          activateAgentSkill(input: $input)
        }
      `;
      const variables = {
        input: {
          auth,
          level,
          provider,
          skillId,
          skillName,
        },
      };
      const { activateAgentSkill: data, error } = await fetchGraphQL({ query, variables });
      return { data: data || false, error };
    },
    [auth, fetchGraphQL, provider]
  );

  const addConferenceParticipant: PhoneContextType['addConferenceParticipant'] = useCallback(
    async (agentIdToAdd) => {
      if (!callData || !callData.interactionId) {
        const error = Error('No call in progress.');
        dispatch({ error, type: 'set_error' });
        return {
          data: false,
          error,
        };
      }
      const query = `
        mutation AddConferenceParticipant($input: AddConferenceParticipantInput!) {
          addConferenceParticipant(input: $input)
        }
      `;
      const variables = {
        input: {
          agentIdToAdd,
          auth,
          callId: callData?.interactionId,
          provider,
        },
      };
      const { addConferenceParticipant: data, error } = await fetchGraphQL({ query, variables });
      return { data: data || false, error };
    },
    [auth, callData, fetchGraphQL, provider]
  );

  const addUserSkill: PhoneContextType['addUserSkill'] = useCallback(
    async (userName, name, level = 1) => {
      const query = `
        mutation AddUserSkill($input: AddUserSkillInput!) {
          addUserSkill(input: $input)
        }
      `;
      const variables = {
        input: {
          level,
          name,
          provider,
          userName,
        },
      };
      const { addUserSkill: data, error } = await fetchGraphQL({ query, variables });
      return { data: data || false, error };
    },
    [fetchGraphQL, provider]
  );

  const addNumberToDnc: PhoneContextType['addNumberToDnc'] = useCallback(
    async (phoneNumber: string) => {
      const query = `
        mutation AddNumberToDnc($input: AddNumberToDncInput!) {
          addNumberToDnc(input: $input)
        }
      `;
      const variables = {
        input: {
          auth,
          phoneNumber,
          provider,
        },
      };
      const { addNumberToDnc: data, error } = await fetchGraphQL({ query, variables });
      return { data: data || false, error };
    },
    [auth, fetchGraphQL, provider]
  );

  const callVoicemail: PhoneContextType['callVoicemail'] = useCallback(
    async (voicemailId) => {
      const query = `
      mutation CallVoicemail($input: CallVoicemailInput!) {
        callVoicemail(input: $input)
      }
    `;
      const variables = {
        input: {
          auth,
          provider,
          voicemailId,
        },
      };
      const { callVoicemail: data, error } = await fetchGraphQL({ query, variables });
      return { data: data || false, error };
    },
    [auth, fetchGraphQL, provider]
  );

  const clearError: PhoneContextType['clearError'] = useCallback(() => {
    dispatch({ error: null, type: 'set_error' });
  }, []);

  const callAttempt = useCallback(async () => {
    if (!state.callData.interactionId) return;
    if (!state.callData.callId) return;
    if (state.activeContact.telephonyId === null) return;
    if (state.activeContact.inquiryId === 0) return;
    if (state.activeContact.phone !== state.callData.number) return;

    if (localStorage.currentCallId === state.callData.callId) return;
    localStorage.currentCallId = state.callData.callId;

    const redial = lastRedialNumber.current === state.activeContact.phone ? true : false;

    const query = `
      mutation callAttempt ($input: CallAttemptInput!) {
        callAttempt (input: $input) {
            callAttemptId
            callId
            inquiryId
            callAttempts
            isLastCallAttempt
        }
      }
    `;
    const variables = {
      input: {
        auth,
        callId: callData.callId,
        contactId: state.activeContact.contactId,
        email: state.activeContact.email,
        inquiryId: Number(state.activeContact.inquiryId),
        oneId: state.activeContact.oneId,
        phoneNumber: state.activeContact.phone,
        provider,
        redial,
        systemCallId: callData.interactionId,
        systemContactId: state.activeContact.telephonyId,
      },
    };
    const { callAttempt, error } = await fetchGraphQL({ query, variables });
    if (error) return;
    lastRedialNumber.current = '';
    return dispatch({
      callAttemptId: callAttempt.callAttemptId,
      callAttempts: callAttempt.callAttempts,
      callId: callAttempt.callId,
      isLastCallAttempt: callAttempt.isLastCallAttempt,
      type: 'set_call_attempt',
    });
  }, [auth, callData, fetchGraphQL, provider, state.activeContact, state.callData]);

  const cancelWarmTransfer: PhoneContextType['cancelWarmTransfer'] =
    useCallback(async (): Promise<void> => {
      if (!state.warmTransfer || !state.warmTransfer.warmTransferId) return;
      const query = `
        mutation CancelWarmTransfer($input: CancelWarmTransferInput!) {
          cancelWarmTransfer(input: $input) {
            ${warmTransferProps}
          }
        }
      `;
      const variables = {
        input: {
          auth,
          provider,
          warmTransferId: state.warmTransfer.warmTransferId,
        },
      };
      const { cancelWarmTransfer } = await fetchGraphQL({ query, variables });
      dispatch({ type: 'set_warm_transfer', warmTransfer: cancelWarmTransfer });
    }, [auth, fetchGraphQL, provider, state.warmTransfer]);

  const completeWarmConference: PhoneContextType['completeWarmConference'] =
    useCallback(async () => {
      if (!activeConference.id) {
        const error = Error('No conference in progress.');
        dispatch({ error, type: 'set_error' });
        return {
          data: false,
          error,
        };
      }
      if (activeConference.state !== CallState.CONFERENCE_PARTICIPANT_CONSULTING) {
        const error = Error('Incorrect conference state.');
        dispatch({ error, type: 'set_error' });
        return { data: false, error };
      }
      const query = `
      mutation CompleteWarmConference($input: CallInput!) {
        completeWarmConference(input: $input)
      }
    `;
      const variables = {
        input: {
          auth,
          callId,
          provider,
        },
      };
      const { completeWarmConference: data, error } = await fetchGraphQL({ query, variables });
      return { data: data || false, error };
    }, [activeConference.id, activeConference.state, auth, callId, provider, fetchGraphQL]);

  const completeWarmTransfer: PhoneContextType['completeWarmTransfer'] = useCallback(
    async ({
      transferCancellationReasonCode,
      transferDetailCode,
      transferResult,
      transferType,
      warmTransferProcessId,
      warmTransferResult,
    }) => {
      const query = `
        mutation CompleteWarmTransfer($input: CompleteWarmTransferInput!) {
          completeWarmTransfer(input: $input)
        }
      `;
      const variables = {
        input: {
          auth,
          provider,
          transferCancellationReasonCode,
          transferDetailCode,
          transferResult,
          transferType,
          userId,
          warmTransferProcessId,
          warmTransferResult,
        },
      };
      const { completeWarmTransfer: data, error } = await fetchGraphQL({ query, variables });
      return { data: data || false, error };
    },
    [auth, fetchGraphQL, provider, userId]
  );

  const createWarmTransfer: PhoneContextType['createWarmTransfer'] = useCallback(
    async (warmTransferId): Promise<WarmTransfer> => {
      const query = `
        mutation CreateWarmTransfer($input: CreateWarmTransferInput!) {
          createWarmTransfer(input: $input) {
            ${warmTransferProps}
          }
        }
      `;
      const variables = {
        input: {
          auth,
          provider,
          warmTransferId,
        },
      };
      const { createWarmTransfer } = await fetchGraphQL({ query, variables });
      dispatch({ type: 'set_warm_transfer', warmTransfer: createWarmTransfer });
      return createWarmTransfer;
    },
    [auth, fetchGraphQL, provider]
  );

  const deactivateAgentSkill: PhoneContextType['deactivateAgentSkill'] = useCallback(
    async (skillName) => {
      const query = `
        mutation DeactivateAgentSkill($input: DeactivateAgentSkillInput!) {
          deactivateAgentSkill(input: $input)
        }
      `;
      const variables = {
        input: {
          auth,
          provider,
          skillName,
        },
      };
      const { deactivateAgentSkill: data, error } = await fetchGraphQL({ query, variables });
      return { data: data || false, error };
    },
    [auth, fetchGraphQL, provider]
  );

  const declineWarmTransfer: PhoneContextType['declineWarmTransfer'] = useCallback(
    async (warmTransferProcessId) => {
      const query = `
        mutation DeclineWarmTransfer($input: DeclineWarmTransferInput!) {
          declineWarmTransfer(input: $input)
        }
      `;
      const variables = {
        input: {
          auth,
          provider,
          warmTransferProcessId,
        },
      };
      const { declineWarmTransfer } = await fetchGraphQL({ query, variables });
      if (declineWarmTransfer !== true) {
        console.error('Problem declining warm transfer offer');
      }
    },
    [auth, fetchGraphQL, provider]
  );

  const deleteCallback: PhoneContextType['deleteCallback'] = useCallback(
    async ({ inquiryId, listName, phoneNumber }) => {
      const query = `
      mutation DeleteCallFromList($input: DeleteCallFromListInput!) {
        deleteCallFromList(input: $input) {
          uploadErrorsCount
          crmRecordsInserted
          crmRecordsUpdated
          listRecordsDeleted
          listRecordsInserted
        }
      }
    `;
      const variables = {
        input: {
          auth,
          inquiryId,
          listName,
          phoneNumber,
          provider,
        },
      };
      const { deleteCallFromList, error } = await fetchGraphQL({ query, variables });
      if (error) return;
      if (deleteCallFromList.uploadErrorsCount >= 1) {
        return dispatch({ error: Error('Error cancelling callback.'), type: 'set_error' });
      }
    },
    [auth, fetchGraphQL, provider]
  );

  const deleteCallFromCampaign: PhoneContextType['deleteCallFromCampaign'] = useCallback(
    async ({ campaignName, inquiryId, phoneNumber }) => {
      const query = `
      mutation DeleteCallFromListsByCampaign($input: DeleteCallFromListsByCampaignInput!) {
        deleteCallFromListsByCampaign(input: $input) {
          uploadErrorsCount
          crmRecordsInserted
          crmRecordsUpdated
          listRecordsDeleted
          listRecordsInserted
        }
      }
    `;
      const variables = {
        input: {
          auth,
          campaignName,
          inquiryId,
          phoneNumber,
          provider,
        },
      };
      const { deleteCallFromListsByCampaign, error } = await fetchGraphQL({ query, variables });
      if (error) return;
      if (deleteCallFromListsByCampaign.uploadErrorsCount >= 1) {
        return dispatch({ error: Error('Error deleting call from campaign.'), type: 'set_error' });
      }
    },
    [auth, fetchGraphQL, provider]
  );

  const deleteCallsFromLists: PhoneContextType['deleteCallsFromLists'] = useCallback(
    async (phoneNumbers) => {
      const query = `
      mutation DeleteCallsFromLists($input: DeleteCallsFromListsInput!) {
        deleteCallsFromLists(input: $input) {
          uploadErrorsCount
          crmRecordsInserted
          crmRecordsUpdated
          listRecordsDeleted
          listRecordsInserted
        }
      }
    `;
      const variables = {
        input: {
          auth,
          phoneNumbers,
          provider,
        },
      };
      const { deleteCallsFromLists: data, error } = await fetchGraphQL({ query, variables });
      return { data: data || false, error };
    },
    [auth, fetchGraphQL, provider]
  );

  const deleteVoicemail: PhoneContextType['deleteVoicemail'] = useCallback(
    async (voicemailId) => {
      const query = `
      mutation DeleteVoicemail($input: DeleteVoicemailInput!) {
        deleteVoicemail(input: $input)
      }
    `;
      const variables = {
        input: {
          auth,
          provider,
          voicemailId,
        },
      };
      const { deleteVoicemail: data, error } = await fetchGraphQL({ query, variables });
      return { data: data || false, error };
    },
    [auth, fetchGraphQL, provider]
  );

  const enablePush: PhoneContextType['enablePush'] = useCallback(async () => {
    const registration = await navigator.serviceWorker.getRegistration();
    if (!registration) {
      console.error('No service worker registered.  Cannot enable push.');
      return dispatch({ isPushEnabled: false, type: 'set_push_enabled' });
    }

    let subscription = null;

    // unsubscribe from any existing subscription we don't recognize
    subscription = await registration.pushManager.getSubscription();
    if (subscription && subscription.options.applicationServerKey) {
      const existingApplicationServerKey = arrayBufferToBase64(
        subscription.options.applicationServerKey
      );
      if (existingApplicationServerKey !== VapidPublicKey[apiEnv || PhoneApiEnv.prod]) {
        console.warn('Unsubscribing from unrecognized push subscription.');
        await subscription.unsubscribe();
      }
    }

    // use existing subscription or create a new subscription
    subscription = await registration.pushManager.getSubscription();
    if (subscription) {
      console.warn('Using existing push subscription.');
    } else {
      try {
        const pushOptions = {
          applicationServerKey: VapidPublicKey[apiEnv || PhoneApiEnv.prod],
          userVisibleOnly: true,
        };
        console.warn('Subscribing to push with options:', pushOptions);
        subscription = await registration.pushManager.subscribe(pushOptions);
      } catch (e) {
        if (e.name === 'NotAllowedError') {
          console.error('Push notification permission denied', e);
        } else {
          console.error('Push notification error:', e);
        }
        if (subscription) {
          await subscription.unsubscribe();
        }
        return dispatch({ isPushEnabled: false, type: 'set_push_enabled' });
      }
    }

    // Subscribe to message events from wt-service-worker
    navigator.serviceWorker.addEventListener(
      'message',
      async (event: { data: unknown }): Promise<void> => {
        const message = event.data as PushMessage;
        switch (message.type) {
          case ServiceWorkerMessageType.offer:
          case ServiceWorkerMessageType.accept:
          case ServiceWorkerMessageType.decline:
          case ServiceWorkerMessageType.disconnected: {
            return dispatch({
              type: 'set_warm_transfer_offer',
              warmTransferOffer: message as WarmTransferOffer,
            });
          }
          case ServiceWorkerMessageType.offerResponse: {
            const offerResponse = message as WarmTransferOffer;
            const warmTransferProcessId = offerResponse.warmTransferProcessId;
            const action = offerResponse.action;
            if (action === 'decline') {
              return declineWarmTransfer(warmTransferProcessId);
            } else {
              return acceptWarmTransfer(warmTransferProcessId);
            }
          }
        }
      }
    );

    // call graphql mutation for push subscription
    // MUST call when auth (userId) changes to support multiple users of a single device + browser
    const query = `
      mutation RegisterPushUrl($input: RegisterPushUrlInput!) {
        registerPushUrl(input: $input) {
          userId
          agentId
        }
      }
    `;
    const variables = {
      input: {
        auth,
        email,
        endpoint: subscription.endpoint,
        expirationTime: subscription.expirationTime,
        keys: subscription.toJSON().keys,
        provider,
        userId,
      },
    };

    const { error, registerPushUrl } = await fetchGraphQL({ query, variables });
    if (error)
      return dispatch({
        error: Error('Unable to register for Warm Transfer notifications.'),
        type: 'set_error',
      });
    console.warn('Push registration successful', registerPushUrl);
    return dispatch({ isPushEnabled: true, type: 'set_push_enabled' });
  }, [
    acceptWarmTransfer,
    apiEnv,
    auth,
    declineWarmTransfer,
    email,
    fetchGraphQL,
    provider,
    userId,
  ]);

  const endCall: PhoneContextType['endCall'] = useCallback(async () => {
    const query = `
      mutation EndCall($input: CallInput!) {
        endCall(input: $input)
      }
    `;
    const variables = {
      input: {
        auth,
        callId: callData?.interactionId,
        provider,
      },
    };
    const { endCall: data, error } = await fetchGraphQL({ query, variables });
    return { data: data || false, error };
  }, [auth, callData, fetchGraphQL, provider]);

  const findNumbersInDnc: PhoneContextType['findNumbersInDnc'] = useCallback(
    async (phoneNumbers: string[]) => {
      const query = `
        query FindNumbersInDnc($input: FindNumbersInDncInput!) {
          findNumbersInDnc(input: $input)
        }
      `;
      const variables = {
        input: {
          auth,
          phoneNumbers,
          provider,
        },
      };
      const { findNumbersInDnc } = await fetchGraphQL({ query, variables });
      return findNumbersInDnc;
    },
    [auth, fetchGraphQL, provider]
  );

  const getAgent = useCallback(async () => {
    const query = `
      query GetAgent(
        $getAgentInput: GetAgentInput!,
        $getAgentStateInput: GetAgentStateInput!, 
        $getCallPreviewsInput: GetCallPreviewsInput!,
        $getCampaignsInput: GetCampaignsInput!, 
        $getVoicemailsInput: GetVoicemailsInput!, 
        $getInquiryFieldsInput: GetInquiryFieldsInput!
        $getLoginStateInput: GetLoginStateInput!
        $getLogoutReasonCodesInput: GetLogoutReasonCodesInput!
        $getSkillsInput: GetSkillsInput!
        $getActiveSkillsInput: GetActiveSkillsInput!
        $getCallsInput: GetCallsInput!
      ) {
        agentInfo: getAgent(input: $getAgentInput) {
          availableChannels
          email
          id
          name
          userName
        }
        agentState: getAgentState(input: $getAgentStateInput) {
          id
          name
        }
        agentStates: getAgentStates(input: $getAgentStateInput) {
          id
          isSelectable
          name
        }
        callPreviews: getCallPreviews(input: $getCallPreviewsInput) {
          id
          campaignId
          contact {
            SLA
            audienceKey
            contactId
            desiredCity
            desiredPostalCode
            email
            familyFileId
            firstName
            inquiryId
            lastName
            oneId
            phone
            relationToResident
            residentName
          }
          state
        }
        campaigns: getCampaigns(input: $getCampaignsInput) {
          campaignType
          id
          name
          state
        }
        inquiryFields: getInquiryFields(input: $getInquiryFieldsInput) {
          id
          name
          dataType
          primary
        }
        voicemailConnection: getVoicemails(input: $getVoicemailsInput) {
          ${voicemailConnectionProps}
        }
        telephonyLoginState: getLoginState(input: $getLoginStateInput)
        skills: getSkills(input: $getSkillsInput) {
          id
          name
        }
        skillIds: getActiveSkills(input: $getActiveSkillsInput)
        logoutReasonCodes: getLogoutReasonCodes(input: $getLogoutReasonCodesInput) {
          id
          name
          selectable
        }
        calls: getCalls (input: $getCallsInput) {
          activeContact {
            SLA
            audienceKey
            contactId
            desiredCity
            desiredPostalCode
            email
            familyFileId
            firstName
            inquiryId
            lastName
            oneId
            phone
            relationToResident
            residentName
            sourceId
            submissionUrl
            subSourceId
            utmCampaign
            utmMedium
            utmSource
            utmTerm
            utmContent
          }
          agent
          agentName
          ani
          callId
          callType
          campaignId
          campaignName
          contactDisplayName
          dnis
          id
          interactionId
          number
          sessionId
          state
          type
        }
      }
    `;
    const variables = {
      getActiveSkillsInput: { auth, provider },
      getAgentInput: { auth, provider },
      getAgentStateInput: { auth, provider },
      getCallPreviewsInput: { auth, provider },
      getCallsInput: { auth, provider },
      getCampaignsInput: { auth, provider, state: CampaignState.RUNNING },
      getInquiryFieldsInput: { auth, provider },
      getLoginStateInput: { auth, provider },
      getLogoutReasonCodesInput: { auth, provider },
      getSkillsInput: { auth, provider },
      getVoicemailsInput: { auth, pageSize: 50, provider },
    };
    const {
      skillIds,
      agentInfo,
      agentState,
      agentStates,
      callPreviews,
      calls,
      campaigns,
      error,
      inquiryFields,
      skills,
      telephonyLoginState,
      voicemailConnection,
      logoutReasonCodes,
    } = await fetchGraphQL({
      query,
      variables,
    });
    if (error) return;

    if (localStorage.callAttempt) {
      dispatch({
        ...JSON.parse(localStorage.callAttempt),
        type: 'set_call_attempt',
      });
    }

    if (localStorage.callData) {
      dispatch({
        callData: { ...JSON.parse(localStorage.callData) },
        type: 'set_call_data',
      });
    }

    if (localStorage.callState) {
      dispatch({
        payload: { ...JSON.parse(localStorage.callState) },
        type: 'set_call_state',
      });
    }

    if (localStorage.activeContact) {
      dispatch({
        ...JSON.parse(localStorage.activeContact),
        type: 'set_active_contact',
      });
    }

    return dispatch({
      agentInfo,
      agentState,
      agentStates,
      callPreviews,
      calls,
      campaigns,
      inquiryFields,
      loginState: toLoginState(telephonyLoginState),
      logoutReasonCodes,
      skillIds,
      skills,
      type: 'set_agent',
      voicemailConnection,
    });
  }, [auth, fetchGraphQL, provider]);

  const setWarmTransferToSLA: PhoneContextType['setWarmTransferToSLA'] = useCallback(
    async ({
      beaconInquiryId,
      contactId,
      familyFileId,
      leadId,
      oneId,
      postalCode,
      slaAgentId,
      slaEmailId,
      city,
      state,
    }): Promise<Advisor[]> => {
      const query = `
        mutation SetWarmTransferToSLA ($input: SetWarmTransferToSLAInput!) {
          setWarmTransferToSLA (input: $input) {
            ${advisorProps}
          }
        }
      `;
      const variables = {
        input: {
          agentId: slaAgentId,
          auth,
          beaconInquiryId,
          callId: callData?.callId,
          city,
          contactId,
          createdBy: 'beacon-client',
          familyFileId,
          leadId,
          oneId,
          postalCode,
          provider,
          slaEmailId,
          state,
        },
      };
      const { error, setWarmTransferToSLA: availableAdvisors } = await fetchGraphQL({
        query,
        variables,
      });
      if (error) {
        throw error;
      }
      dispatch({ availableAdvisors, type: 'set_available_advisors' });
      return availableAdvisors;
    },
    [auth, callData, provider, fetchGraphQL]
  );

  const getAgentForTransfer: PhoneContextType['getAgentForTransfer'] = useCallback(
    async ({
      beaconInquiryId,
      city,
      contactId,
      eligibleForAsla = true,
      familyFileId,
      leadId,
      oneId,
      oppgenInquiryId,
      poolType = 1,
      postalCode,
      primaryContactName,
      primaryContactRelationship,
      residentName,
      state,
    }): Promise<Advisor[]> => {
      const query = `
        query GetAgentForTransfer($input: GetAgentForTransferInput!) {
          getAgentForTransfer(input: $input) {
            ${advisorProps}
          }
        }
      `;
      const variables = {
        input: {
          auth,
          beaconInquiryId,
          callId: callData?.callId,
          city,
          contactId,
          createdBy: 'beacon-client',
          eligibleForAsla,
          familyFileId,
          leadId,
          oneId,
          oppgenInquiryId,
          poolType,
          postalCode,
          primaryContactName,
          primaryContactRelationship,
          provider,
          residentName,
          state,
        },
      };
      const { error, getAgentForTransfer: availableAdvisors } = await fetchGraphQL({
        query,
        variables,
      });
      if (error) {
        throw error;
      }
      dispatch({ availableAdvisors, type: 'set_available_advisors' });
      return availableAdvisors;
    },
    [auth, callData, fetchGraphQL, provider]
  );

  const getDispositions = useCallback(
    async (campaignId) => {
      const query = `
      query GetDispositions($input: GetDispositionsInput!) {
        getDispositions(input: $input) {
          id
          name
          type
        }
      }
    `;
      const variables = {
        input: { auth, campaignId, provider },
      };
      const { error, getDispositions } = await fetchGraphQL({ query, variables });
      if (error) return;
      return dispatch({ dispositions: getDispositions, type: 'set_dispositions' });
    },
    [auth, fetchGraphQL, provider]
  );

  const getLoggedInUsers = useCallback(async () => {
    const query = `
        query GetUsers($input: GetUsersInput!) {
          getUsers(input: $input) {
            agentId
            email
            extension
            fullName
            phoneNumber
            role
            userName
          }
        }
      `;
    const variables = {
      input: {
        auth,
        isLoggedIn: true,
        provider,
      },
    };
    const { error, getUsers } = await fetchGraphQL({ query, variables });
    if (error) return;
    return getUsers;
  }, [auth, fetchGraphQL, provider]);

  const getSkills = useCallback(async () => {
    const query = `
      query GetSkills($input: GetSkillsInput!) {
        skills: getSkills(input: $input) {
          id
          name
        }
      }
    `;
    const variables = {
      input: {
        auth,
        provider,
      },
    };

    const { skills } = await fetchGraphQL({ query, variables });
    dispatch({ skills, type: 'set_skills' });
  }, [auth, fetchGraphQL, provider]);

  const getVoicemail: PhoneContextType['getVoicemail'] = useCallback(
    async (voicemailId) => {
      const query = `
        query getVoicemail($input: GetVoicemailInput!) {
          voicemail: getVoicemail(input: $input) {
            ${voicemailProps}
          }
        }
      `;
      const variables = {
        input: {
          auth,
          provider,
          voicemailId,
        },
      };

      const { voicemail } = await fetchGraphQL({ query, variables });
      dispatch({ type: 'set_voicemail', voicemail });
      return voicemail;
    },
    [auth, fetchGraphQL, provider]
  );

  const getVoicemails: PhoneContextType['getVoicemails'] = useCallback(
    async (pageSize = 50, cursor) => {
      const query = `
        query GetVoicemails($input: GetVoicemailsInput!) {
          voicemailConnection: getVoicemails(input: $input) {
            ${voicemailConnectionProps}
          }
        }
      `;
      const variables = {
        input: {
          auth,
          cursor,
          pageSize,
          provider,
        },
      };
      const { error, voicemailConnection } = await fetchGraphQL({ query, variables });
      if (error) return;
      dispatch({ type: 'set_voicemails', voicemailConnection });
      return voicemailConnection;
    },
    [auth, fetchGraphQL, provider]
  );

  const getWarmTransfer: PhoneContextType['getWarmTransfer'] = useCallback(
    async (warmTransferId): Promise<WarmTransfer> => {
      const query = `
        query GetWarmTransfer($input: GetWarmTransferInput!) {
          getWarmTransfer(input: $input) {
            ${warmTransferProps}
          }
        }
      `;
      const variables = {
        input: {
          auth,
          provider,
          warmTransferId,
        },
      };
      const { getWarmTransfer } = await fetchGraphQL({ query, variables });
      dispatch({ type: 'set_warm_transfer', warmTransfer: getWarmTransfer });
      return getWarmTransfer;
    },
    [auth, fetchGraphQL, provider]
  );

  const makeExternalCall: PhoneContextType['makeExternalCall'] = useCallback(
    async (campaignId, phoneNumber) => {
      const query = `
        mutation MakeExternalCall($input: MakeExternalCallInput!) {
          makeExternalCall(input: $input)
        }
      `;
      const variables = {
        input: {
          auth,
          campaignId,
          phoneNumber,
          provider,
        },
      };
      const { makeExternalCall: data, error } = await fetchGraphQL({ query, variables });
      return { data: data || false, error };
    },
    [auth, fetchGraphQL, provider]
  );

  const makeInternalCall: PhoneContextType['makeInternalCall'] = useCallback(
    async (destinationAgentId) => {
      const query = `
      mutation MakeInternalCall($input: MakeInternalCallInput!) {
        makeInternalCall(input: $input)
      }
    `;
      const variables = {
        input: {
          auth,
          destinationAgentId,
          provider,
        },
      };
      const { makeInternalCall: data, error } = await fetchGraphQL({ query, variables });
      return { data: data || false, error };
    },
    [auth, fetchGraphQL, provider]
  );

  const makePreviewCall: PhoneContextType['makePreviewCall'] = useCallback(async () => {
    const query = `
      mutation MakePreviewCall($input: MakePreviewCallInput!) {
        makePreviewCall(input: $input)
      }
    `;
    const variables = {
      input: {
        auth,
        provider,
      },
    };
    const { makePreviewCall: data, error } = await fetchGraphQL({ query, variables });
    return { data: data || false, error };
  }, [auth, fetchGraphQL, provider]);

  const redial: PhoneContextType['redial'] = useCallback(async () => {
    const query = `
      mutation redial($input: RedialInput!) {
        redial(input: $input) {
          callAttemptId
        }
      }
    `;
    const variables = {
      input: {
        auth,
        callAttemptId: state.callAttempt.callAttemptId,
        campaignId: callData.campaignId,
        inquiryId: Number(state.activeContact.inquiryId),
        phoneNumber: state.activeContact.phone,
        provider,
      },
    };
    const { redial: data, error } = await fetchGraphQL({ query, variables });
    lastRedialNumber.current = state.activeContact.phone || '';
    return { data: data || false, error };
  }, [auth, callData.campaignId, fetchGraphQL, provider, state.activeContact, state.callAttempt]);

  const removeUserSkill: PhoneContextType['removeUserSkill'] = useCallback(
    async (userName, name) => {
      const query = `
        mutation RemoveUserSkill($input: RemoveUserSkillInput!) {
          removeUserSkill(input: $input)
        }
      `;
      const variables = {
        input: {
          name,
          provider,
          userName,
        },
      };
      const { removeUserSkill: data, error } = await fetchGraphQL({ query, variables });
      return { data: data || false, error };
    },
    [fetchGraphQL, provider]
  );

  const removeNumbersFromDnc: PhoneContextType['removeNumbersFromDnc'] = useCallback(
    async (phoneNumbers: string[]) => {
      const query = `
        mutation RemoveNumbersFromDnc($input: RemoveNumbersFromDncInput!) {
          removeNumbersFromDnc(input: $input)
        }
      `;
      const variables = {
        input: {
          phoneNumbers,
          provider,
        },
      };
      const { removeNumbersFromDnc: data, error } = await fetchGraphQL({ query, variables });
      return { data: data || false, error };
    },
    [fetchGraphQL, provider]
  );

  const setAgentState: PhoneContextType['setAgentState'] = useCallback(
    async (agentStateId) => {
      const agentStateFound = state.agentStates.find((s) => s.id === agentStateId);
      if (!agentStateFound) throw Error('AgentStateId not found in agentStates');
      const { id, name } = agentStateFound;
      const query = `
        mutation SetAgentState($input: SetAgentStateInput!) {
          setAgentState(input: $input) {
            id
            name
          }
        }
      `;
      const variables = {
        input: {
          agentState: { id, name },
          auth,
          provider,
          readyChannels: state.availableChannels.filter((c) => Object.keys(Channels).includes(c)),
        },
      };
      const { error, setAgentState } = await fetchGraphQL({ query, variables });
      if (error) return;
      return dispatch({ agentState: setAgentState, type: 'set_agent_state' });
    },
    [auth, fetchGraphQL, provider, state.agentStates, state.availableChannels]
  );

  const logout: PhoneContextType['logout'] = useCallback(
    async (logoutReasonCodeId) => {
      const logoutReasonCodeFound = state.logoutReasonCodes.find(
        (s) => s.id === logoutReasonCodeId
      );
      if (!logoutReasonCodeFound) throw Error('logoutReasonCodeId not found');
      const { id, name, selectable } = logoutReasonCodeFound;
      const query = `
        mutation logout($input: LogoutInput!) {
          logout(input: $input)
        }
      `;
      const variables = {
        input: {
          auth,
          force: false,
          provider,
          reasonCodeInfo: { id, name, selectable },
        },
      };
      const { error } = await fetchGraphQL({ query, variables });
      if (error) return dispatch({ error, type: 'set_error' });
      broadcastChannel.postMessage(
        JSON.stringify({
          context: {
            eventId: AgentEventsSupplement.LOGOUT,
          },
          payLoad: {},
        })
      );
      localStorage.clear();
      window.location.reload();
    },
    [auth, fetchGraphQL, provider, state.logoutReasonCodes]
  );

  const setCampaign: PhoneContextType['setCampaign'] = useCallback(
    async (campaignId) => {
      const query = `
        mutation SetCampaign($input: SetCampaignInput!) {
          setCampaign(input: $input) {
            id
            name
          }
        }
      `;
      const variables = {
        input: {
          auth,
          campaignId,
          provider,
        },
      };
      const { setCampaign: data, error } = await fetchGraphQL({ query, variables });
      return { data: data || false, error };
    },
    [auth, fetchGraphQL, provider]
  );

  const skipPreviewCall: PhoneContextType['skipPreviewCall'] = useCallback(
    async (dispositionId) => {
      const query = `
      mutation skipPreviewCall($input: SkipPreviewCallInput!) {
        skipPreviewCall(input: $input) 
      }
    `;
      const variables = {
        input: {
          auth,
          dispositionId,
          provider,
        },
      };
      const { skipPreviewCall: data, error } = await fetchGraphQL({ query, variables });
      return { data: data || false, error };
    },
    [auth, fetchGraphQL, provider]
  );

  const setDisposition: PhoneContextType['setDisposition'] = useCallback(
    async (dispositionId, dispositionName) => {
      const query = `
        mutation setDisposition($input: SetDispositionInput!) {
          setDisposition(input: $input) {
            success
            callAttempts
            updatedList {
              listRecordsDeleted
            }
          }
        }
    `;
      const variables = {
        input: {
          auth,
          callId: callData?.interactionId,
          campaignName: callData?.campaignName,
          dispositionId,
          dispositionName,
          inquiryId: Number(state.activeContact.inquiryId),
          phoneNumber: callData?.number,
          provider,
        },
      };
      const { setDisposition: data, error } = await fetchGraphQL({ query, variables });
      return { data: data || false, error };
    },
    [auth, callData, fetchGraphQL, provider, state.activeContact.inquiryId]
  );

  const setHolding: PhoneContextType['setHolding'] = useCallback(
    async (isHolding) => {
      const query = `
      mutation setHolding($input: SetHoldingInput!) {
        setHolding(input: $input)
      }
    `;
      const variables = {
        input: {
          auth,
          callId: callData?.interactionId,
          isHolding,
          provider,
        },
      };
      const { error, setHolding } = await fetchGraphQL({ query, variables });
      if (error) return;
      return dispatch({ isHolding: setHolding, type: 'set_holding' });
    },
    [auth, callData, fetchGraphQL, provider]
  );

  const showNotification: PhoneContextType['showNotification'] = useCallback(
    async (title, notification) => {
      const registration = await navigator.serviceWorker.getRegistration();
      let actions: NotificationAction[] = [];
      if (notification.data && notification.data.type === ServiceWorkerMessageType.offerResponse) {
        actions = [
          {
            action: 'accept',
            title: 'Accept',
          },
          {
            action: 'decline',
            title: 'Decline',
          },
        ];
      }
      if (registration) {
        registration.showNotification(title, {
          actions,
          icon: 'logo192.png',
          lang: 'en',
          vibrate: [500, 100, 500],
          ...notification,
        });
      }
    },
    []
  );

  const transferExternalCall: PhoneContextType['transferExternalCall'] = useCallback(
    async (phoneNumber, dispositionId = '-23', dispositionName = 'Transferred To 3rd Party') => {
      const query = `
        mutation transferExternalCall($input: TransferExternalCallInput!) {
          transferExternalCall(input: $input)
        }
      `;
      const variables = {
        input: {
          auth,
          callId: callData.interactionId,
          destination: {
            destination: {
              number: phoneNumber,
              skipDNCCheck: true,
            },
            dispositionId,
            warm: false,
          },
          dispositionName,
          provider,
        },
      };
      const { transferExternalCall: data, error } = await fetchGraphQL({ query, variables });
      return {
        data: data || false,
        error,
      };
    },
    [auth, callData.interactionId, fetchGraphQL, provider]
  );

  const transferLead: PhoneContextType['transferLead'] = useCallback(
    async (warmTransferProcessId) => {
      if (!callData || !callData.interactionId) {
        const error = Error('No call in progress.');
        dispatch({ error, type: 'set_error' });
        return { data: false, error };
      }

      dispatch({
        activeConference: getInitialState().activeConference,
        type: 'set_active_conference',
      });

      const query = `
        mutation TransferLead($input: TransferLeadInput!) {
          transferLead(input: $input)
        }
      `;
      const variables = {
        input: {
          auth,
          callId: callData.interactionId,
          provider,
          warmTransferProcessId,
        },
      };
      const { transferLead: data, error } = await fetchGraphQL({ query, variables });
      return { data: data || false, error };
    },
    [auth, callData, fetchGraphQL, provider]
  );

  const leaveConference: PhoneContextType['leaveConference'] = useCallback(
    async (dispositionId, dispositionName) => {
      if (!callData || !callData.interactionId) {
        const error = Error('No call in progress.');
        dispatch({ error, type: 'set_error' });
        return { data: false, error };
      }
      const query = `
        mutation leaveConference($input: LeaveConferenceInput!) {
          leaveConference(input: $input)
        }
      `;
      const variables = {
        input: {
          auth,
          callId: callData.interactionId,
          dispositionId,
          dispositionName,
          provider,
        },
      };
      const { leaveConference: data, error } = await fetchGraphQL({ query, variables });
      return { data: data || false, error };
    },
    [auth, callData, fetchGraphQL, provider]
  );

  const initiateWarmTransfer: PhoneContextType['initiateWarmTransfer'] = useCallback(
    async ({
      beaconInquiryId,
      city,
      contactId,
      eligibleForAsla = true,
      familyFileId,
      leadId,
      oneId,
      oppgenInquiryId,
      poolType = 1,
      postalCode,
      primaryContactName,
      primaryContactRelationship,
      residentName,
      state,
      slaEmailId,
      slaAgentId,
    }) => {
      if (!familyFileId && !leadId) {
        throw new Error('Either familyFileId or leadId is required');
      }
      if (!auth) {
        dispatch({ error: Error('Not authenticated'), type: 'set_error' });
        return;
      }
      const agentId = JSON.parse(auth).agentId;

      dispatch({
        type: 'set_warm_transfer',
        warmTransfer: {
          acceptedSlaAgentIds: [],
          familyFileId,
          isWarmTransferCompleted: false,
          isWarmTransferInitiated: true,
          leadId,
          pingGroup: undefined,
          slaOfferTimeout: undefined,
          srcAgentId: agentId,
          warmTransferId: '',
          winningSlaAgentId: undefined,
        },
      });

      dispatch({
        activeConference: getInitialState().activeConference,
        type: 'set_active_conference',
      });

      let availableAdvisors;
      try {
        if (!slaAgentId && !slaEmailId) {
          availableAdvisors = await getAgentForTransfer({
            beaconInquiryId,
            city,
            contactId,
            eligibleForAsla,
            familyFileId,
            leadId,
            oneId,
            oppgenInquiryId,
            poolType,
            postalCode,
            primaryContactName,
            primaryContactRelationship,
            residentName,
            state,
          });
        } else {
          availableAdvisors = await setWarmTransferToSLA({
            beaconInquiryId,
            city,
            contactId,
            eligibleForAsla,
            familyFileId,
            leadId,
            oneId,
            oppgenInquiryId,
            postalCode,
            primaryContactName,
            primaryContactRelationship,
            residentName,
            slaAgentId,
            slaEmailId,
            state,
          });
        }
      } catch (e) {
        dispatch({
          type: 'set_warm_transfer',
          warmTransfer: {
            acceptedSlaAgentIds: [],
            error: e,
            familyFileId,
            isWarmTransferCompleted: false,
            isWarmTransferInitiated: true,
            leadId,
            pingGroup: undefined,
            slaOfferTimeout: undefined,
            srcAgentId: agentId,
            warmTransferId: '',
            winningSlaAgentId: undefined,
          },
        });
        return;
      }

      if (availableAdvisors.length === 0) {
        dispatch({ error: Error('No agents available for warm transfer.'), type: 'set_error' });
        dispatch({ type: 'set_warm_transfer', warmTransfer: null });
        return;
      }

      const warmTransfer = await createWarmTransfer(availableAdvisors[0].warmTransferId);

      if (!warmTransfer) {
        return;
      }

      if (warmTransfer.isWarmTransferCompleted) {
        return;
      }

      try {
        setTimeout(async function pingWT() {
          const wt = await getWarmTransfer(warmTransfer.warmTransferId);
          if (wt && !wt.isWarmTransferCompleted) {
            setTimeout(pingWT, 1000);
          }
        }, 1000);
      } catch (e) {
        dispatch({ type: 'set_warm_transfer', warmTransfer: null });
        console.warn('Error polling warm transfer', e);
      }
    },
    [auth, createWarmTransfer, getAgentForTransfer, getWarmTransfer, setWarmTransferToSLA]
  );

  const setState = useCallback(
    (arg) => {
      if (typeof arg === 'function') {
        const nextState = arg(state);
        return dispatch({ state: nextState, type: 'set_state' });
      }
      const nextState = {
        ...state,
        ...arg,
      };
      return dispatch({ state: nextState, type: 'set_state' });
    },
    [state]
  );

  const removeConferenceParticipant: PhoneContextType['removeConferenceParticipant'] =
    useCallback(async () => {
      const query = `
      mutation RemoveConferenceParticipant($input: CallInput!) {
        removeConferenceParticipant(input: $input)
      }
    `;
      const variables = {
        input: {
          auth,
          callId: callData?.interactionId,
          provider,
        },
      };
      const { removeConferenceParticipant: data, error } = await fetchGraphQL({ query, variables });
      return { data: data || false, error };
    }, [auth, callData, fetchGraphQL, provider]);

  const sendDTMF: PhoneContextType['sendDTMF'] = useCallback(
    async (sendDTMF) => {
      return sendIframeMessage({ sendDTMF });
    },
    [sendIframeMessage]
  );

  const setMute: PhoneContextType['setMute'] = useCallback(
    async (setMute) => {
      return sendIframeMessage({ setMute });
    },
    [sendIframeMessage]
  );

  const getMuteState: PhoneContextType['getMuteState'] = useCallback(async () => {
    return sendIframeMessage({ getMuteState: true });
  }, [sendIframeMessage]);

  const setVoicemailAsRead: PhoneContextType['setVoicemailAsRead'] = useCallback(
    async (voicemailId) => {
      const query = `
      mutation SetVoicemailAsRead($input: GetVoicemailInput!) {
        setVoicemailAsRead(input: $input) {
          voicemailId
        }
      }
    `;
      const variables = {
        input: {
          auth,
          provider,
          voicemailId,
        },
      };
      const { setVoicemailAsRead: data, error } = await fetchGraphQL({ query, variables });
      return { data: data || false, error };
    },
    [auth, fetchGraphQL, provider]
  );

  const setVoicemailComment: PhoneContextType['setVoicemailComment'] = useCallback(
    async (voicemailId, comment) => {
      const query = `
      mutation SetVoicemailComment($input: SetVoicemailCommentInput!) {
        setVoicemailComment(input: $input)
      }
    `;
      const variables = {
        input: {
          auth,
          comment,
          provider,
          voicemailId,
        },
      };
      const { setVoicemailComment: data, error } = await fetchGraphQL({ query, variables });
      return { data: data || false, error };
    },
    [auth, fetchGraphQL, provider]
  );

  const scheduleCallback: PhoneContextType['scheduleCallback'] = useCallback(
    async (inquiryId, oneId, callTime, campaignName, listName, phoneNumber, disposition) => {
      const query = `
      mutation AddCallToList($input: AddCallToListInput!) {
        addCallToList(input: $input) {
          uploadErrorsCount
          crmRecordsInserted
          crmRecordsUpdated
          listRecordsDeleted
          listRecordsInserted
        }
      }
    `;
      const variables = {
        input: {
          auth,
          callTime,
          campaignName,
          disposition,
          inquiryId,
          listName,
          oneId,
          phoneNumber,
          provider,
        },
      };
      const { addCallToList, error } = await fetchGraphQL({ query, variables });
      if (error) return;
      if (addCallToList.uploadErrorsCount >= 1) {
        return dispatch({ error: Error('Error scheduling callback.'), type: 'set_error' });
      }
    },
    [auth, fetchGraphQL, provider]
  );

  const transferVoicemailToAgent: PhoneContextType['transferVoicemailToAgent'] = useCallback(
    async (voicemailId, transferAgentId) => {
      const query = `
      mutation TransferVoicemail($input: TransferVoicemailInput!) {
        transferVoicemail(input: $input)
      }
    `;
      const variables = {
        input: {
          auth,
          provider,
          transferAgentId,
          voicemailId,
        },
      };
      const { transferVoicemail: data, error } = await fetchGraphQL({ query, variables });
      return { data: data || false, error };
    },
    [auth, fetchGraphQL, provider]
  );

  const transferVoicemailToSkill: PhoneContextType['transferVoicemailToSkill'] = useCallback(
    async (voicemailId, transferSkillId) => {
      const query = `
      mutation TransferVoicemail($input: TransferVoicemailInput!) {
        transferVoicemail(input: $input)
      }
    `;
      const variables = {
        input: {
          auth,
          provider,
          transferSkillId,
          voicemailId,
        },
      };
      const { transferVoicemail: data, error } = await fetchGraphQL({ query, variables });
      return { data: data || false, error };
    },
    [auth, fetchGraphQL, provider]
  );

  useEffect(() => {
    if (auth && callData.campaignId) getDispositions(callData.campaignId);
  }, [auth, callData.campaignId, getDispositions]);

  useEffect(() => {
    if (state.activeContact.telephonyId != null && state.callData.interactionId) {
      callAttempt();
    }
  }, [callAttempt, state.activeContact, state.callData.interactionId]);

  useEffect(() => {
    if (state.isAuthenticated && state.auth) {
      getAgent();
    }
  }, [getAgent, state.auth, state.isAuthenticated]);

  useEffect(() => {
    if (state.isAuthenticated && state.auth) {
      getMuteState();
    }
  }, [getMuteState, state.auth, state.isAuthenticated]);

  useEffect(() => {
    if (state.isFetchingSkills === true) {
      getSkills();
    }
  }, [getSkills, state.isFetchingSkills]);

  useEffect(() => {
    interactionId.current = state.interactionId;
  }, [state.interactionId]);

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (!event.data) return;
      if (!event.source) return;

      if (event.data.sender === 'adt-connect-custom') {
        iframeWindowInitEvent.current = event;
        if (event.data.request === 'includeFile') {
          sendIframeMessage({
            includeFile: window.location.origin + '/iframe-inject.js',
          });
        }
      }

      if (event.data.sender === 'iframe-inject') {
        if (event.data.isMuted !== undefined) {
          const isMuted = event.data.isMuted;
          broadcastChannel.postMessage(
            JSON.stringify({
              context: {
                eventId: AgentEventsSupplement.CALL_IS_MUTED,
              },
              payLoad: {
                isMuted,
              },
            })
          );
          dispatch({ isMuted, type: 'set_muted' });
        }
      }
    });
  }, [apiEnv, sendIframeMessage]);

  return useMemo(() => {
    console.log('%cPhoneProvider context value ', 'color: lightgreen;', state);
    const value = {
      ...state,
      acceptSkillVoicemail,
      acceptWarmTransfer,
      activateAgentSkill,
      addConferenceParticipant,
      addNumberToDnc,
      addUserSkill,
      callVoicemail,
      cancelWarmTransfer,
      clearError,
      completeWarmConference,
      completeWarmTransfer,
      createWarmTransfer,
      deactivateAgentSkill,
      declineWarmTransfer,
      deleteCallFromCampaign,
      deleteCallback,
      deleteCallsFromLists,
      deleteVoicemail,
      enablePush,
      endCall,
      findNumbersInDnc,
      getAgentForTransfer,
      getLoggedInUsers,
      getMuteState,
      getVoicemail,
      getVoicemails,
      getWarmTransfer,
      initiateWarmTransfer,
      leaveConference,
      logout,
      makeExternalCall,
      makeInternalCall,
      makePreviewCall,
      provider,
      redial,
      removeConferenceParticipant,
      removeNumbersFromDnc,
      removeUserSkill,
      scheduleCallback,
      sendDTMF,
      setAgentState,
      setCampaign,
      setDisposition,
      setHolding,
      setMute,
      setSkills: setSkills({ dispatch, fetchGraphQL, provider, state }),
      setState,
      setStation: setStation({ dispatch, fetchGraphQL, provider, state }),
      setVoicemailAsRead,
      setVoicemailComment,
      setWarmTransferToSLA,
      showNotification,
      skipPreviewCall,
      transferExternalCall,
      transferLead,
      transferVoicemailToAgent,
      transferVoicemailToSkill,
    };
    return (
      <PhoneContext.Provider value={value}>
        {provider === 'five9' ? (
          <Five9
            {...state}
            broadcastChannel={broadcastChannel}
            dispatch={dispatch}
            iframeContainer={iframeContainer}
            iframeStyle={iframeStyle}
            logLevel={apiEnv === PhoneApiEnv.local || apiEnv === PhoneApiEnv.dev ? 'warn' : null}
          />
        ) : null}
        {children}
      </PhoneContext.Provider>
    );
  }, [
    acceptSkillVoicemail,
    acceptWarmTransfer,
    activateAgentSkill,
    addConferenceParticipant,
    addNumberToDnc,
    addUserSkill,
    apiEnv,
    callVoicemail,
    cancelWarmTransfer,
    children,
    clearError,
    completeWarmConference,
    completeWarmTransfer,
    createWarmTransfer,
    deactivateAgentSkill,
    declineWarmTransfer,
    deleteCallFromCampaign,
    deleteCallback,
    deleteCallsFromLists,
    deleteVoicemail,
    enablePush,
    endCall,
    fetchGraphQL,
    findNumbersInDnc,
    getAgentForTransfer,
    getLoggedInUsers,
    getMuteState,
    getVoicemail,
    getVoicemails,
    getWarmTransfer,
    iframeContainer,
    iframeStyle,
    initiateWarmTransfer,
    leaveConference,
    logout,
    makeExternalCall,
    makeInternalCall,
    makePreviewCall,
    provider,
    redial,
    removeConferenceParticipant,
    removeNumbersFromDnc,
    removeUserSkill,
    scheduleCallback,
    sendDTMF,
    setAgentState,
    setCampaign,
    setDisposition,
    setHolding,
    setMute,
    setState,
    setVoicemailAsRead,
    setVoicemailComment,
    setWarmTransferToSLA,
    showNotification,
    skipPreviewCall,
    state,
    transferExternalCall,
    transferLead,
    transferVoicemailToAgent,
    transferVoicemailToSkill,
  ]);
};

export default PhoneProvider;
